// Angular Related
import { NgModule, APP_INITIALIZER, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router'
import { HttpClientModule } from '@angular/common/http'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Dependancy
import { NgSelectModule } from '@ng-select/ng-select'
import { NgClickOutsideModule } from 'ng-click-outside2'
import { ClassValidatorFormBuilderModule } from 'ngx-reactive-form-class-validator'
import { ORDERED_APP_INITIALIZER, orderedAppInitializer } from 'ngx-ordered-initializer'

import { SwiperModule } from 'swiper/angular'
import { InfiniteScrollModule } from "ngx-infinite-scroll";

// Fortunebit Solutions Dependancy
import { environment } from '../environments/environment'
import { StorageNoSql } from 'fortunebit-staffhub/src/core/storage-nosql.service'
import { GlobalVariables } from 'fortunebit-staffhub/src/core/globalvar.service'
import { globalvar_provider } from 'fortunebit-staffhub/src/providers/globalvar.provider'
import { storageNoSql_Provider } from 'fortunebit-staffhub/src/providers/storage-nosql.provider';
import { ShareableModule } from './shareable/shareable.module';

// Bootstrap dependancy
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BaseChartDirective } from 'ng2-charts';
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api'

// Global Emmiters - Part of main services
import { SideMenuEmitService } from 'fortunebit-staffhub/src/core/global-emitters/glob-side-menu.emit.services'
import { ModalService } from 'fortunebit-staffhub/src/core/modal.service';
import { LoaderService } from '@desktop-common-comp/services/loader.service'
import { ToastService } from '@desktop-common-comp/services/toast.service';
import { EleaveComponent } from './eleave/eleave.component';
import { EattendanceComponent } from './eattendance/eattendance.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateClaimPage } from './eclaim/create-claim/create-claim.page';
import { CreateLeavePage } from './eleave/create-leave/create-leave.page';
import { ProfileComponent } from './profile/profile.component';
import { EleaveListingService } from './eleave/eleave-listing/services/eleave-listing.service';

@NgModule({
  declarations: [
    AppComponent,
    EattendanceComponent,
    CreateClaimPage,
    ProfileComponent,
    // CreateLeavePage
    // EleaveComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    AppRoutingModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ClassValidatorFormBuilderModule.forRoot(),
    SwiperModule,
    NgSelectModule,
    NgClickOutsideModule,
    InfiniteScrollModule,
    ShareableModule,
    BaseChartDirective,
    NgxSpinnerModule,
    ToastModule,
  ],
  providers: [
    { provide: 'ENVIRONMENT', useValue: environment },
    { provide: ORDERED_APP_INITIALIZER, useFactory: storageNoSql_Provider, deps: [StorageNoSql], multi: true },
    { provide: ORDERED_APP_INITIALIZER, useFactory: globalvar_provider, deps: [GlobalVariables], multi: true },
    { provide: APP_INITIALIZER, useFactory: orderedAppInitializer, deps: [ORDERED_APP_INITIALIZER], multi: true },
    { provide: 'SIDEMENUGLOBAL', useClass: SideMenuEmitService },
    ModalService,
    LoaderService,
    EleaveListingService,
    MessageService,
    ToastService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
