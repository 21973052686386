import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgOption, NgSelectComponent } from '@ng-select/ng-select';
import { isEmpty } from 'class-validator';
import { Observable, Subject, Subscription } from 'rxjs';
import { NgselectEleaveCategoryComponent } from '../ngselect-eleave-category/ngselect-eleave-category.component'
import { EleaveCategoryService } from 'desktop-app/src/app/eleave/eleave-approve/services/eleave-category.service';
@Component({
  selector: 'app-ngselect-advance-category',
  templateUrl: './ngselect-advance-category.component.html',
  styleUrls: ['./ngselect-advance-category.component.scss']
})
export class NgselectAdvanceCategoryComponent implements OnInit {

  public categoryLoading = false
  public categorySelected: Array<[]> = []
  public subStorage: Array<Subscription> = []
  public selectedLeaveType
  categoryInput$ = new Subject<string>()
  categoryList: [] = []

  @ViewChild("categorySelect") public categorySelect: NgSelectComponent
  @Input('selectedCategory') public categoryMonth: Array<[]> = []
  @Input('removeSelected') public removeSelected = new EventEmitter<string>()
  @Output('selectedCategoryOutput') public selectedCategoryOutput = new EventEmitter<string>()
  @Output('leaveType') public leaveAllocations = new EventEmitter<string>();
  @Output('selectedItem') public selectedItem = new EventEmitter<string>()

  displayCategoryList = new EventEmitter<[]>()


  constructor(
    @Inject(EleaveCategoryService) private readonly eleaveCategoryService: EleaveCategoryService
  ) {

    let leaveCategSub = this.eleaveCategoryService.$eleaveCategoryChangeDetact.subscribe(category => {
      // console.log({ category });
      this.selectedLeaveType = ''
    })
    this.subStorage.push(leaveCategSub)
  }

  ngOnInit(): void { }


  onChangeLeaveType(leaveType) {
    this.selectedLeaveType = leaveType
    this.selectedCategoryOutput.emit(leaveType)
  }
}
