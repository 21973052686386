import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgOption, NgSelectComponent } from '@ng-select/ng-select';
import { isEmpty } from 'class-validator';
import { Observable, Subject, Subscription } from 'rxjs';
import { NgselectAdvanceCategoryComponent } from '../ngselect-advance-category/ngselect-advance-category.component'
import { EleaveCategoryService } from '../../../eleave/eleave-approve/services/eleave-category.service';

@Component({
  selector: 'app-ngselect-eleave-category',
  templateUrl: './ngselect-eleave-category.component.html',
  styleUrls: ['./ngselect-eleave-category.component.scss']
})
export class NgselectEleaveCategoryComponent implements OnInit, OnDestroy {

  public categoryLoading = false
  public categorySelected: Array<[]> = []
  public selectedLeaveType
  public advanceTrigger: boolean = false
  public selectedAdvanceItem: string = ''
  public subStorage: Array<Subscription> = []
  categoryInput$ = new Subject<string>()
  categoryList: [] = []

  @ViewChild("categorySelect") public categorySelect: NgSelectComponent
  @Input('selectedCategory') public categoryMonth: Array<[]> = []
  @Input('removeSelected') public removeSelected = new EventEmitter<string>()
  @Output('selectedCategoryOutput') public selectedCategoryOutput = new EventEmitter<string>()
  @Output('leaveType') leaveAllocations = new EventEmitter<string>();
  @Output('selectedeleaveItem') selectedeleaveItem = new EventEmitter<string>()
  displayCategoryList = new EventEmitter<[]>()


  constructor(
    @Inject(EleaveCategoryService) private readonly eleaveCategoryService: EleaveCategoryService
  ) {
    let advanceCategSub = this.eleaveCategoryService.$advanceCategoryChangeDetact.subscribe(category => {
      // console.log({ category });
      this.selectedLeaveType = category
      this.advanceTrigger = true
    })
    this.subStorage.push(advanceCategSub)
  }

  ngOnInit(): void {
    this.selectedLeaveType = 'Annual Leave'
    this.onChangeLeaveType(this.selectedLeaveType)
  }

  ngOnDestroy(): void {
    this.subStorage.map(sub => {
      sub.unsubscribe()
    })
  }

  onChangeLeaveType(leaveType) {
    this.selectedLeaveType = leaveType
    this.advanceTrigger = false
    this.selectedCategoryOutput.emit(leaveType)
  }
}
