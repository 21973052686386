import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { SideMenuEmitService } from 'fortunebit-staffhub/src/core/global-emitters/glob-side-menu.emit.services'
import { GlobalVariables } from 'fortunebit-staffhub/src/libs';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    constructor(
        @Inject(Router) public router: Router,
        @Inject(GlobalVariables) public global: GlobalVariables,
        @Inject('SIDEMENUGLOBAL') private sideMenuService: SideMenuEmitService
    ) { }

    ngOnInit(): void { }

    activateMenu() {
        // Does not store state, So just emit random event
        this.sideMenuService.emitEvent(true)
    }

    navigateNotification() {
        this.router.navigate(['notification/notification-listing'])
    }
}
