<div class="single-search-inner hideshow show row pb-3 " style="margin:2px auto 0px auto; padding-bottom: 8px !important;">
  <div class="single-search-inner content col-12 col-sm-6" style="max-width:50%; margin: 5px auto 0px auto">
 
      <div class="text-left">
        <div class="selectEmployee"><b>Select Month</b></div>
        <div class="relative">
          <ng-select class="monthSelect" #monthSelect style="border-radius: 20px;" [items]="monthList" bindLabel="description" [virtualScroll]="true" [(ngModel)]="selectedMonth"></ng-select>
          <i class="fa fa-search" style="right: 18px !important;"></i>
        </div>
      </div>  
      <div class="thelist selected text-left noshowdef" data-targeted="comp"></div>
          <!-- <i class="fa fa-search"></i> -->
  </div>
    
  <div class="single-search-inner content col-12 col-sm-6" style="max-width:50%; margin: 5px auto 0px auto">
    <div class="text-left">
      <div class="selectEmployee"><b>Select Week</b></div>
      <div class="relative">
        <ng-select #weekSelect style="border-radius: 20px;" 
        [(ngModel)]="currentWeekStr">
          <ng-option *ngFor="let weekAccord of weeksAccodMonth; let i = index" bindLabel="description" [virtualScroll]="true" value="{{i}}">Week {{i + 1}}:
            <span style="font-weight: bolder !important;">{{weekAccord.startDate}} -
              {{weekAccord.endDate}}</span></ng-option>
        </ng-select>
        <i class="fa fa-search" style="right: -7px !important;"></i>
      </div>
    </div>
    <div class="thelist selected text-left noshowdef" data-targeted="comp"></div>
    <!-- <i class="fa fa-search"></i> -->
  </div>
</div>
