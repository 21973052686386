import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { NgOption, NgSelectComponent } from '@ng-select/ng-select';
import { isEmpty } from 'class-validator';
import { Observable, Subject } from 'rxjs';
import * as eleaveTypes from 'projects/fortunebit-staffhub/src/eleave/services/typings/eleave-api.typings'

@Component({
  selector: 'progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit, OnDestroy {
  public progress = '0'
  public progressPercent = '0'
  public minProgress = 0
  public maxProgress = 100
  public leaveType: eleaveTypes.LeaveDetails
  public isCarryForward = false
  public isLeaveDetails = false
  public leaveDetails = {}
  public carryForwardDetails:any = {}
  @Input('allocationDetails') public allocationDetails: EventEmitter<eleaveTypes.LeaveDetails>
  constructor() {
    this.progress = '80%'
    this.progressPercent = '63%'
  }

  ngOnInit() {
    this.allocationDetails.subscribe(data => {
      console.log({ data });
      this.leaveDetails = {}
      this.carryForwardDetails = {}
      this.isCarryForward = false
      this.isLeaveDetails = false
      if (data) {
        this.leaveType = data
        this.destructData(data)
      }

    })
  }

  destructData(data: eleaveTypes.LeaveDetails) {

    console.log(data.allocation_details)
    // data.allocation_details.forEach(e => {
    //   console.log(e)
    //   if (e.is_carry_forward == 1) {
    //     console.log("hey")
    //     this.carryForwardDetails = e
    //     this.isCarryForward = true
    //   } else {
    //     this.leaveDetails = e
    //     this.isLeaveDetails = true
    //   }
    // })
    if (data.carry_forward > 0) {
      // console.log("hey")
      this.carryForwardDetails = data.allocation_details[1]
      this.isCarryForward = true
    } 
    // else {
      this.leaveDetails = data.allocation_details[0]
      this.isLeaveDetails = true
    // }
    if (this.carryForwardDetails) {
      // console.log("hallo",this.carryForwardDetails)
      this.carryForwardDetails['carry_forward_taken'] = data.carry_forward_taken
      this.carryForwardDetails['carry_foward'] = data.carry_forward
      this.carryForwardDetails['expired_carry_forward'] = data.expired_carry_forward
      this.carryForwardDetails['progress_left'] = (data.carry_forward_taken + data.expired_carry_forward).toString()
      var percent = (data.carry_forward_taken/ (data.carry_forward - data.expired_carry_forward)) * 100
    //  console.log(percent)
      this.carryForwardDetails['progress_percent'] = `${percent.toString()}%`
      this.carryForwardDetails['minProgress'] = 0
      this.carryForwardDetails['maxProgress'] = data.carry_forward
      this.carryForwardDetails['leave_type'] = 'Carry Forward'
    }
    // console.log(data)
    // console.log(this.carryForwardDetails)

    if (this.leaveDetails) {
      this.leaveDetails = { ...this.leaveDetails, ...data }
      this.leaveDetails['progress_left'] = data.leaves_taken.toString()
      this.leaveDetails['progress_percent'] = `${(data.leaves_taken / data.total_leaves) * 100}%`
      this.leaveDetails['minProgress'] = 0
      this.leaveDetails['maxProgress'] = data.total_leaves
    }

    // console.log(this.leaveDetails)
  }

  ngOnDestroy(): void {
    this.allocationDetails.complete()
  }

}
