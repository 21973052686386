import { Component, EventEmitter, Input, OnInit, Output, Renderer2, Inject, AfterViewInit, HostListener, ChangeDetectorRef, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { BehaviorSubject, debounceTime, fromEvent, Subscription } from 'rxjs';
import { Platform } from '@ionic/angular'
import * as eleaveTypes from 'projects/fortunebit-staffhub/src/eleave/services/typings/eleave-api.typings'
import { EleaveApproveService } from '../services/eleave-approve.service';
import { EleaveFilterService } from '../services/eleave-filter.service';

@Component({
  selector: 'app-side-highlighted',
  templateUrl: './side-highlighted.component.html',
  styleUrls: ['./side-highlighted.component.scss']
})

export class SideHighlightedComponent implements OnInit, AfterViewInit, OnDestroy {

  public department: string = ''
  public reports_to: string = ''
  public companyName: string = ''
  public renderComponent: boolean = false
  public offsetFlag: boolean = true;
  public maxWidth: string = ''
  public rightAlign: string = ''
  public selectedApprovalData: eleaveTypes.EleaveSummaryInfo
  static VarObservablePad: BehaviorSubject<number> = new BehaviorSubject(0);
  static SelectedObservable: BehaviorSubject<number> = new BehaviorSubject(0);
  public scrollY: number = 0
  public bottomPosition: string = '0';
  public selectedLeft: string = "44px";
  public selectedTransform: string = "translateY(-188px)"
  public Selectedheight: number = 0;
  public selectedwidth: string = "17.5%";
  public subscriptions: Subscription[] = []
  public selectedHighlighted: eleaveTypes.EleaveEmployeeDetailsHR
  public selectedEmployeeHRID: string = ''
  static SelectedDataObservable = new EventEmitter<Object>()

  @Input('componentHeight') public componentHeight: number
  @Input('ParentSideData') public parentSideData: eleaveTypes.EleaveSummaryInfo = new eleaveTypes.EleaveSummaryInfoDefault()// Setting default value
  @Output('approveEleaveSelected') public approveEleaveSelected = new EventEmitter<eleaveTypes.EleaveSummaryInfo>()
  @Input() public childApprovalData: Array<eleaveTypes.LeaveApplicationDatas> = []
  @ViewChild('employeeInfo') employeeInfo: ElementRef;

  constructor(
    @Inject(Platform) private platform: Platform,
    @Inject(Renderer2) private readonly renderer2: Renderer2,
    @Inject(ChangeDetectorRef) private readonly changeDetectRef: ChangeDetectorRef,
    @Inject(EleaveFilterService) public readonly eleaveFilterService: EleaveFilterService,
    @Inject(EleaveApproveService) public readonly eleaveApproveService: EleaveApproveService
  ) { }

  ngOnInit(): void {
    // this.initializeData(this.parentSideData)
    // this.initializePosition()
    // this.initializeWidth()
  }


  ngAfterViewInit(): void {
    this.subscriptions.push(this.eleaveFilterService.$parentLeaveData.pipe(debounceTime(200)).subscribe((parent) => {
      console.log({ parent });
      if (parent) {
        this.renderComponent = true
        this.selectedHighlighted = parent
      }
      this.changeDetectRef.detectChanges()
    }))
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.map((sub) => {
        sub.unsubscribe()
      })
    }
    this.renderComponent = false
  }

  initializePosition() {
    if (window.outerHeight > 1080 && window.outerHeight <= 1294) {
      // console.log(window.outerHeight, 'window.outerHeight');
      const percentageOfOffset = Math.round(window.outerHeight * (60.5 / 100))
      // console.log(percentageOfOffset, 'percentageOfOffset');
      this.bottomPosition = window.outerHeight - percentageOfOffset + 'px';
    }

    else if (window.outerHeight > 1000 && window.outerHeight <= 1080) {
      // console.log(window.outerHeight, 'window.outerHeight');
      const percentageOfOffset = Math.round(window.outerHeight * (52.40 / 100))
      // console.log(percentageOfOffset, 'percentageOfOffset');
      this.bottomPosition = window.outerHeight - percentageOfOffset + 'px';
      // this.bottomPosition = `calc(100% - ${percentageOfOffset}px)`
      // console.log(this.bottomPosition, 'bottomPosition');
    } else if (window.outerHeight > 906 && window.outerHeight <= 1000) {
      // console.log(window.outerHeight, 'window.outerHeight');
      const percentageOfOffset = Math.round(window.outerHeight * (50 / 100))
      // console.log(percentageOfOffset, 'percentageOfOffset');
      this.bottomPosition = window.outerHeight - percentageOfOffset + 'px';
      // this.bottomPosition = `calc(100% - ${percentageOfOffset}px)`
      // console.log(this.bottomPosition, 'bottomPosition');
    }

    else if (window.outerHeight <= 906) {
      // console.log(window.outerHeight, 'window.outerHeight');
      const percentageOfOffset = Math.round(window.outerHeight * (30 / 100))
      // console.log(percentageOfOffset, 'percentageOfOffset');
      this.bottomPosition = window.outerHeight - percentageOfOffset + 'px';
      // this.bottomPosition = `calc(100% - ${percentageOfOffset}px)`
      // console.log(this.bottomPosition, 'bottomPosition');
    }
  }

  initializeWidth() {
    if (window.innerWidth > 1280 && window.innerWidth <= 1536) {
      this.maxWidth = 82 + '% !important'
      this.rightAlign = 67 + 'px'
    } else if (window.innerWidth > 1195 && window.innerWidth <= 1280) {
      this.maxWidth = 91 + '% !important'
      this.rightAlign = 32 + 'px'
    } else if (window.innerWidth > 1135 && window.innerWidth <= 1195) {
      // this.maxWidth = 82 + '% !important'
      this.rightAlign = 30 + 'px'
    } else if (window.innerWidth > 1025 && window.innerWidth <= 1135) {
      this.maxWidth = 91 + '% !important'
      this.rightAlign = 25 + 'px'
    } else if (window.innerWidth > 988 && window.innerWidth <= 1025) {
      this.maxWidth = 91 + '% !important'
      this.rightAlign = 25 + 'px'
    } else if (window.innerWidth > 812 && window.innerWidth <= 988) {
      // this.maxWidth = 91 + '% !important'
      this.bottomPosition = 25 + 'px';
      this.rightAlign = 28 + 'px'
    } else if (window.innerWidth > 521 && window.innerWidth <= 812) {
      // this.maxWidth = 91 + '% !important'
      this.bottomPosition = 25 + 'px';
      this.rightAlign = 24 + 'px'
    } else if (window.innerWidth > 338 && window.innerWidth <= 521) {
      // this.maxWidth = 91 + '% !important'
      this.bottomPosition = 25 + 'px';
      this.rightAlign = 14 + 'px'
    } else if (window.innerWidth <= 338) {
      // this.maxWidth = 91 + '% !important'
      this.bottomPosition = 25 + 'px';
      this.rightAlign = 10 + 'px'
    } else {
      this.maxWidth = ''
      this.rightAlign = ''
    }
  }

  setValue(height, left, width) {
    this.Selectedheight = height
    this.selectedLeft = left
    this.selectedwidth = width
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    console.log({ event });
    if (event.currentTarget.innerWidth > 1280 && event.currentTarget.innerWidth <= 1536) {
      this.maxWidth = 82 + '% !important'
      this.rightAlign = 67 + 'px'
    } else if (event.currentTarget.innerWidth > 1195 && event.currentTarget.innerWidth <= 1280) {
      this.maxWidth = 91 + '% !important'
      this.rightAlign = 32 + 'px'
    } else if (event.currentTarget.innerWidth > 1135 && event.currentTarget.innerWidth <= 1195) {
      // this.maxWidth = 82 + '% !important'
      this.rightAlign = 30 + 'px'
    } else if (event.currentTarget.innerWidth > 1025 && event.currentTarget.innerWidth <= 1135) {
      // this.maxWidth = 82 + '% !important'
      this.rightAlign = 28 + 'px'
    } else if (event.currentTarget.innerWidth > 988 && event.currentTarget.innerWidth <= 1025) {
      // this.maxWidth = 91 + '% !important'
      this.rightAlign = 25 + 'px'
    } else if (event.currentTarget.innerWidth > 812 && event.currentTarget.innerWidth <= 988) {
      // this.maxWidth = 91 + '% !important'
      this.bottomPosition = 25 + 'px';
      this.rightAlign = 28 + 'px'
    } else if (event.currentTarget.innerWidth > 521 && event.currentTarget.innerWidth <= 812) {
      // this.maxWidth = 91 + '% !important'
      this.bottomPosition = 25 + 'px';
      this.rightAlign = 24 + 'px'
    } else if (event.currentTarget.innerWidth > 338 && event.currentTarget.innerWidth <= 521) {
      // this.maxWidth = 91 + '% !important'
      this.bottomPosition = 25 + 'px';
      this.rightAlign = 14 + 'px'
    } else if (event.currentTarget.innerWidth <= 338) {
      // this.maxWidth = 91 + '% !important'
      this.bottomPosition = 25 + 'px';
      this.rightAlign = 10 + 'px'
    } else {
      this.maxWidth = ''
      this.rightAlign = ''
    }
    // this.screenResolution()
  }

  initializeData(data: eleaveTypes.EleaveSummaryInfo) {
    // console.log(data)
    data.department === null ? this.department = 'NULL' : this.department = data.department
    data.reports_to === null ? this.reports_to = 'NULL' : this.reports_to = data.reports_to
    data.company === null ? this.companyName = 'NULL' : this.companyName = data.company
  }
  test(parent: eleaveTypes.EleaveEmployeeDetailsHR) {
    // console.log(parent)
  }

  openEmplyeeInfo() {
    // console.log('openEmplyeeInfo');
    const element = this.employeeInfo.nativeElement;
    if (element.classList.contains('show-content')) {
      this.renderer2.removeClass(element, 'show-content');
      this.renderer2.addClass(element, 'float-content');
    } else {
      this.renderer2.removeClass(element, 'float-content');
      this.renderer2.addClass(element, 'show-content');
    }
  }

  getEmployeeGenderImage(parentLeaveData: eleaveTypes.EleaveEmployeeDetailsHR) {
    if (parentLeaveData.gender == 'Male') {
      return "./assets/img/icon/man.png"
    } else if (parentLeaveData.gender == 'Female') {
      return "./assets/img/icon/woman.png"
    } else {
      return "./assets/img/profile-face-woman.jpg"
    }
  }





}
