<div *ngIf="renderComponent" #employeeInfo (click)="openEmplyeeInfo()"
  class="offtop ba-balance-inner card-style float-content mw-100" data-contentfilter="pending"
  [style.max-width]="maxWidth" [style.right]="rightAlign">
  <div class="d-flex justify-content-between align-items-center w-100" style="cursor: pointer;">
    <div class="col-4 icon icon-eclaim" style="padding:0px; margin:-5px; background:none;">
      <div class="header-user photo_renderer"
        [style.backgroundImage]="'url(' + getEmployeeGenderImage(selectedHighlighted) + ')'" style="margin:auto; 
        background-repeat: no-repeat; 
        background-position: center center; 
        background-size:cover; 
        width:100%; 
        height:100%; border-radius: 6666px; 
        image-rendering: -webkit-optimize-contrast; 
        border:1px solid #ccc;
        background-color: white;">
      </div>
    </div>
    <div class="col-4 employee-name" style="padding-left: 15%; text-wrap: nowrap;">
      <h5 class="title" style="color: white;">{{selectedHighlighted.employee_name}}</h5>
    </div>
    <div class="col-4 SelectedSidInfo align-items-center">
      <ul class="list-content">
        <li class="" style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; color: white;">
          Employee ID: <b>{{selectedHighlighted.employee}}</b>
        </li>
      </ul>
    </div>
  </div>
</div>