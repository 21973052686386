import { trigger, transition, style, query, sequence, animate, stagger, state, AnimationTriggerMetadata } from "@angular/animations";

export const DropDownAnimation = trigger("dropDownMenu", [
  transition('closed => open', [
    style({ height: 0, overflow: "hidden" }),
    query(".child-item", [
      style({ opacity: 0, transform: "translateY(-10px)" })
    ]),
    sequence([
      animate("300ms", style({ height: "*" })),
      query(".child-item", [
        stagger(-50, [
          animate("600ms ease", style({ opacity: 1, transform: "none" }))
        ])
      ])
    ])
  ]),

  transition("open => closed", [
    style({ height: "*", overflow: "hidden" }),
    query(".child-item", [style({ opacity: 1, transform: "none" })]),
    sequence([
      query(".child-item", [
        stagger(50, [
          animate(
            "400ms ease",
            style({ opacity: 0, transform: "translateY(-50px)" })
          )
        ])
      ]),
      animate("400ms", style({ height: 0 }))
    ])
  ])
]);

export function dropDownAnimation(height: string): AnimationTriggerMetadata {
  return trigger("dropDownMenu", [
    transition('closed => open', [
      style({ height: 0, overflow: "hidden" }),
      query(".child-item", [
        style({ opacity: 0, transform: "translateY(-10px)" })
      ]),
      sequence([
        animate("300ms", style({ height: "*" })),
        query(".child-item", [
          stagger(-50, [
            animate("600ms ease", style({ opacity: 1, transform: "none" }))
          ])
        ])
      ])
    ]),

    transition("open => closed", [
      style({ height: height, overflow: "hidden" }),
      query(".child-item", [style({ opacity: 1, transform: "none" })]),
      sequence([
        query(".child-item", [
          stagger(50, [
            animate(
              "400ms ease",
              style({ opacity: 0, transform: "translateY(-20px)" })
            )
          ])
        ]),
        animate("400ms ease", style({ height: 0 }))
      ])
    ])
  ]);
}
