import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from "@angular/router";
import { GlobalVariables } from '../core/globalvar.service'

@Injectable({
  providedIn: 'root'
})
export class AuthRememberService  {
  constructor(private router: Router, private globalvar: GlobalVariables) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    let authInfo = this.globalvar.auth_global.access_token

    if (authInfo) {
      this.router.navigate(['/home']);
      return false;
    }
    return true
  }
}
