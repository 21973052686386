<app-header-submodule [moduleName]="titleHeader" [moduleDesc]="descHeader"></app-header-submodule>

<div class="balance-area list-screen" style="padding-top:85px;">
    <div class="container-customise text-center">
        <div class="balance-area-bg balance-area-bg-home">
            <div class="row">
                <div class="col-12">
                    <div class="balance-title text-center">

                        <div #profilePic class="header-user photo_renderer"
                            style="margin:auto; background-size:cover; width:100px; height:100px; border-radius:6666px; image-rendering: -webkit-optimize-contrast;">
                            <img alt="man" src="{{global?.user_profile?.profile_image}}" style="border-radius: 50%"
                                width="100px" height="100px" />
                        </div>

                        <h6 *ngIf="global?.user_profile?.full_name != null">
                            {{global?.user_profile?.full_name}}
                        </h6>

                        <form class="contact-form-inner text-left single-search-inner">
                            <div class="form-group row">
                                <div *ngIf="global?.user_profile?.full_name != null" class="col-12">
                                    <label class="single-input-wrap">
                                        <b style="padding: 0px 0px 5px 15px;color: #333333;display: block;">Name:</b>
                                        <input type="text" placeholder="Fast Name" class="disabled"
                                            value="{{global?.user_profile?.full_name}}" readonly required>
                                    </label>
                                </div>
                                <div *ngIf="global?.user_profile?.gender != null" class="col-6">
                                    <label class="single-input-wrap">
                                        <b style="padding: 0px 0px 5px 15px;color: #333333;display: block;">Gender:</b>
                                        <input type="text" placeholder="Fast Name" class="disabled"
                                            value="{{global?.user_profile?.gender}}" readonly required>
                                    </label>
                                </div>
                                <div *ngIf="global?.user_profile?.birth_date != null" class="col-6">
                                    <label class="single-input-wrap">
                                        <b style="padding: 0px 0px 5px 15px;color: #333333;display: block;">Age:</b>
                                        <input type="text" placeholder="Fast Name" class="disabled"
                                            value="{{getUserAge(global?.user_profile?.birth_date)}}" readonly required>
                                    </label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div *ngIf="global?.user_profile?.company != null" class="col-12">
                                    <label class="single-input-wrap">
                                        <b style="padding: 0px 0px 5px 15px;color: #333333;display: block;">Company:</b>
                                        <input type="text" placeholder="Company" class="disabled"
                                            value="{{global?.user_profile?.company}}" readonly required>
                                    </label>
                                </div>
                                <div *ngIf="global?.user_profile?.department != null" class="col-12">
                                    <label class="single-input-wrap">
                                        <b
                                            style="padding: 0px 0px 5px 15px;color: #333333;display: block;">Department:</b>
                                        <input type="text" placeholder="Department" class="disabled"
                                            value="{{global?.user_profile?.department}}" readonly required>
                                    </label>
                                </div>
                                <div *ngIf="global?.user_profile?.designation != null" class="col-12">
                                    <label class="single-input-wrap">
                                        <b
                                            style="padding: 0px 0px 5px 15px;color: #333333;display: block;">Designation:</b>
                                        <input type="text" placeholder="Designation" class="disabled"
                                            value="{{global?.user_profile?.designation}}" readonly required>
                                    </label>
                                </div>
                                <div *ngIf="global?.user_profile?.name != null" class="col-12">
                                    <label class="single-input-wrap">
                                        <b style="padding: 0px 0px 5px 15px;color: #333333;display: block;">Email
                                            Address:</b>
                                        <input type="text" placeholder="Email Address" class="disabled"
                                            value="{{global?.user_profile?.name}}" readonly required>
                                    </label>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>