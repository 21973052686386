import { AfterViewChecked, AfterViewInit, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { EleaveListingService } from 'desktop-app/src/app/eleave/eleave-listing/services/eleave-listing.service';
import { ChangeDetectionService } from 'desktop-app/src/app/eleave/shared/change-detection.service';
import { ExpenseEleaveApi } from 'fortunebit-staffhub/src/libs';
import * as eleaveTypes from 'projects/fortunebit-staffhub/src/eleave/services/typings/eleave-api.typings'
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

@Component({
  selector: 'app-ngselect-status-leave-select',
  templateUrl: './ngselect-status-leave-select.component.html',
  styleUrls: ['./ngselect-status-leave-select.component.scss']
})

export class NgselectStatusLeaveSelectComponent implements OnInit, AfterViewInit, OnDestroy {

  public statusLoading = false
  public statusList: string[] = ['All', 'Pending', 'Approved', 'Rejected', 'Cancelled', 'Draft', 'Pending HOD Approval']
  public statusInput$ = new Subject<string>()
  public statusSubs: Subscription[] = []
  @Input('selectedStatus') public selectedStatus: string = ''
  @ViewChild("statusSelect") public statusSelect: NgSelectComponent
  // @Output('selectedMonthOutput') public selectedMonthOutput = new EventEmitter<Array<eleaveTypes.Status>>()

  constructor(
    @Inject(ChangeDetectionService) private changeDetact: ChangeDetectionService,
    @Inject(EleaveListingService) private eleaveListingService: EleaveListingService
  ) {
    this.selectedStatus = this.statusList[0]
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.trackStatusSelected()
    this.listenFilterSelection()
  }

  ngOnDestroy(): void {
    if (this.statusSubs.length > 0) {
      this.statusSubs.forEach(sub => {
        sub.unsubscribe()
      })
    }
  }

  trackStatusSelected() {
    this.statusSubs.push(this.statusSelect.changeEvent.subscribe(status => {
      // console.log({ status });
      this.eleaveListingService.setStatus(status)
      this.eleaveListingService.triggerChangeDetection()
      // console.log(this.eleaveListingService.buildFilterObject(), 'buildFilterObject');
      // month === undefined ? month = "" : month
      // this.selectedMonthOutput.emit(this.dataInterceptor(month))
    }))
  }

  listenFilterSelection() {
    this.changeDetact.$eleaveSelectedFilterDetaction.pipe(
      debounceTime(100),
      filter(bool => bool === false)
    ).subscribe(bool => {
      if (!bool) {
        this.selectedStatus = this.statusList[0]
      }
    })
  }

}
