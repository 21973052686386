<div class="text-left">
  <div class="selectYear"><b>Select Year</b></div>
  <div class="relative">
    <ng-select style="border-radius: 20px;" #yearSelect [items]="year$" bindLabel="value" [disabled]="disableCompany"
      [multiple]="false" [closeOnSelect]="true" [loading]="yearLoading" [typeahead]="yearInput$"
      typeToSearchText="Year Name" [virtualScroll]="true" [(ngModel)]="selectedYear"
      (change)="onSelectionChange($event)" (add)="onItemAdded($event)" dropdownPosition="auto"
      [clearSearchOnAdd]="true"></ng-select>
    <i class="fa fa-search" style="right: 18px !important;"></i>
  </div>
</div>