import { Injectable } from '@angular/core';
import { Device } from "@capacitor/device"
import { CommonIosModals } from '@common-comp/components/common-ios-modals';
import { Platform } from '@ionic/angular';
import { DeviceType } from 'fortunebit-staffhub/src/typings/device-details';
// import { PushNotificationService } from './push-notification.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceDetails {

  public isIos: boolean = false
  public device_info: DeviceType

  constructor(
    private readonly platform: Platform,
    private readonly iosModals: CommonIosModals
  ) { }

  public async init() {
    return new Promise(async (resolve, reject) => {
      Promise.all([await this.getDeviceInfo()]).then(() => {
        resolve(true)
      }), () => {
        reject(false)
      }
    })
  }

  private async getDeviceInfo() {
    return new Promise(async (resolve, reject) => {
      const info: DeviceType = await Device.getInfo()
      this.device_info = { ...info }
      this.device_info.platform === 'ios' || this.platform.is('ios') ? this.isIos = true : this.isIos = false
      resolve(true)
    })
  }

  isIosContainsNotch() {
    if (this.isIos) {
      const modal = this.device_info.model
      const containsModal = this.iosModals.iPhoneModals.some(item => item.name === modal);
      return containsModal
    }
  }

}
