import { Injectable, Inject } from '@angular/core'
import { HttpServiceService } from '../../core/http-service.service'
import { Observable, Observer } from 'rxjs'
import * as eclaimTypes from './typings/eclaim-api.typings'

@Injectable({
  providedIn: 'root'
})
export class ExpenseClaimApi {
  public ExpenseList: any = []
  constructor(
    @Inject(HttpServiceService) private http: HttpServiceService,
  ) {
    this.fetchExpenseList()
  }


  // Gets Summary Information
  getSummaryList(body: eclaimTypes.GetSummaryListInput) {
    return new Observable((observer: Observer<Array<eclaimTypes.ClaimSummaryInformation>>) => {
      this.http.post('/users/getSummaryList', body).then((data: any) => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }
  ext_getExpenseList() {
    return new Observable((observer: Observer<any>) => {
      this.http.get('/users/getExpenseType').then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }


  fetchExpenseList() {
    this.ext_getExpenseList().subscribe(data => {
      this.ExpenseList = data['results']
    })
  }

  //  this.expenseClaimCore.getVehicleType() 

  // Gets Summary Information by individual user
  getUserClaimDetails() {
    return new Observable((observer: Observer<Array<eclaimTypes.GetUserClaimDetailDatas>>) => {
      this.http.get('/users/getUserClaimSummary').then((data: any) => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  // Getters
  getClaimList(data: eclaimTypes.GetClaimListInput) {
    return new Observable((observer: Observer<Array<eclaimTypes.ExpensesClaimData>>) => {
      this.http.post('/users/getClaimList', data).then((data: any) => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getExpenseList() {
    return new Observable((observer: Observer<Array<eclaimTypes.ExpensesClaimTypes>>) => {
      this.http.get('/users/getExpenseType').then((data: any) => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getVehicleType(data: eclaimTypes.GetVehicleTypeInput) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getVehicleType', data).then((data: any) => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getMillageAmount(data: eclaimTypes.GetMillageAmountInput) {
    return new Observable((observer: Observer<eclaimTypes.ExpenseClaimMillageAmountDatas>) => {
      this.http.post('/users/getMillageAmount', data).then((data: any) => {
        console.log({ data });
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getMillageOtherAmount(data: eclaimTypes.GetMillageOtherAmountInput) {
    // console.log({ data });
    return new Observable((observer: Observer<eclaimTypes.ExpenseClaimMillageAmountDatas>) => {
      this.http.post('/users/getMillageOtherAmount', data).then((data: any) => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }



  getClaimDetails(data: eclaimTypes.GetClaimDetailInput) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getSingleExpenseClaim', data).then((data: any) => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getClaimChildList(data) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getClaimChildList', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getEmployeeList(search) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getEmployeeList', search).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getCompanyList(search) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getCompanyList', search).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  // API For Claim
  getApprovalPermission(body) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getApprovalPermission', body).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  // Get individual claim per user
  getApprovalClaimList(body: eclaimTypes.GetSummaryListInput) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getApprovalClaimList', body).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  // Gets claim information by user
  getApprovalClaimListByUser(body: eclaimTypes.GetApprovalClaimListByUserInput) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getApprovalClaimListGroupByUser', body).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  updateExpenseClaim(body) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/updateClaims', { 'data': body }).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  createExpenseClaim(body) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/createClaims', { 'data': body }).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  approveExpenseClaim(data: eclaimTypes.ApproveExpenseClaimInput) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/approveExpenseClaim', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  approveExpenseClaimByUser(data: eclaimTypes.setAppRejClaim) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/setApprovalClaimListGroupByUser', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  approveExpenseClaimByCheck(data: eclaimTypes.CommonApprovalInput) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/setApprovalClaimListByCheck', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  ext_setExpenseClaimAttachment(body) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/uploadExpenseAttachment', body).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  ext_deleteExpenseClaimAttachment(body) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/deleteExpenseAttachment', body).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }


}