import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-ngselect-type-notification-select',
  templateUrl: './ngselect-type-notification-select.component.html',
  styleUrls: ['./ngselect-type-notification-select.component.scss']
})
export class NgselectTypeNotificationSelectComponent implements OnInit {

  public notificationTypeLoading = false
  public notificationTypeInput$ = new Subject<string>()
  public notificationTypeList: string[] = ['All', 'Pending', 'Approved', 'Rejected', 'Cancelled', 'Draft', 'Pending HOD Approval']

  @Input('notificationType') public notificationType: string = ''

  constructor() {
    this.notificationType = this.notificationTypeList[0]
  }

  ngOnInit(): void {
  }

}
