import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CommonIosModals {

    public iPhoneModals = [
        { name: "iPhone10,3" },
        { name: "iPhone10,6" },
        { name: "iPhone11,2"},
        { name: "iPhone11,4"},
        { name: "iPhone11,6"},
        { name: "iPhone11,8"},
        { name: "iPhone12,1"},
        { name: "iPhone12,3"},
        { name: "iPhone12,5"},
        { name: "iPhone12,8"},
        { name: "iPhone13,1"},
        { name: "iPhone13,2"},
        { name: "iPhone13,3"},
        { name: "iPhone13,4"},
        { name: "iPhone14,2"},
        { name: "iPhone14,3"},
        { name: "iPhone14,4"},
        { name: "iPhone14,5"},
        { name: "iPhone14,6"},
        { name: "iPhone14,7"},
        { name: "iPhone14,8"},
        { name: "iPhone15,2"},
        { name: "iPhone15,3"},
        { name: "iPhone15,4"},
        { name: "iPhone15,5"},
        { name: "iPhone16,1"},
        { name: "iPhone16,2"}
    ];

    constructor() { }

}