import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild, ViewContainerRef, OnDestroy, Renderer2, HostListener } from '@angular/core';
import { Platform } from '@ionic/angular';
import { EleaveApproverChangeDetactionService } from 'fortunebit-staffhub/src/eleave/shared/eleave-approver-change-detaction.service';
import * as eleaveTypes from 'projects/fortunebit-staffhub/src/eleave/services/typings/eleave-api.typings'
import { BehaviorSubject } from 'rxjs';
import { NgselectEleaveCategoryComponent } from '@desktop-common-comp/components/ngselect-eleave-category/ngselect-eleave-category.component';
import { NgselectAdvanceCategoryComponent } from '@desktop-common-comp/components/ngselect-advance-category/ngselect-advance-category.component';
import { jQueryServices } from '@desktop-common-comp/services/jquery.services'
import { Subscription } from 'rxjs'
import { EleaveCategoryService } from '../services/eleave-category.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-parent-eleave',
  templateUrl: './parent-eleave.component.html',
  styleUrls: ['./parent-eleave.component.scss']
})

export class ParentEleaveComponent implements OnInit, AfterViewInit, OnDestroy {

  public reason: string = ''
  public department: string = ''
  public reports_to: string = ''
  public companyName: string = ''
  public childOpened: Boolean = false
  public annualLeave: Boolean = true
  public compensatoryLeave: Boolean = false
  public annual_replacement_Leave: Boolean = false
  public noLeaveAllocation: boolean = false
  public allocationOpened: Boolean = true
  public appliedLeaveType: boolean = true
  public hospitalizationLeave: Boolean = false
  public scrollLoading: boolean = false
  public visibility: any = 'hover'
  public appearance: any = 'compact'
  public overflowX: string = 'hidden'
  public overflowY: string = 'scroll'
  public selectedLeaveType = ''
  public thumbSize: number = 10
  public VarObservable: BehaviorSubject<Boolean> = new BehaviorSubject(false);
  public VarObservable2: BehaviorSubject<Boolean> = new BehaviorSubject(false);
  public checkAllClicked = new BehaviorSubject(false)
  public leaveTypesArray: Array<string>
  public leaveAllocationData: eleaveTypes.LeaveAllocationData
  public selectedLeaveDetails: eleaveTypes.LeaveDetails
  public infiniteScrollDisabled: boolean = false
  public leaveAllocationKeys: Array<string>
  public popOverOpened: Boolean = false
  public openedCategory: Boolean = false
  static OpenTogggle = new EventEmitter<String>()
  static leaveAllocations = new EventEmitter<any>()
  public imageUrl: String ="./assets/img/icon/annual_l.png"
  public leaveAllocationName: Object =
    {
      "carry_forward": ["Carry Forward", "Carry Forward"],
      "carry_forward_taken": ["Carry Forward Taken", "Carry Forward Taken"],
      "expired_leaves": ["Expired Leaves", "Expired Leaves"],
      "leaves_allocated": ["Leaves Allocated", "Leaves Allocated"],
      "leaves_taken": ["Leaves Taken", "Leaves Taken"],
      "pending_leaves": ["Pending Leaves", "Pending Leaves"],
      "prorated_leaves": ["Prorated Leaves", "Prorated Leaves"],
      "remaining_leaves": ["Remaining Leaves", "Remaining Leaves"],
      "total_leaves": ["Total Leaves", "Total Leaves"],
      "unused_carry_forward": ["Unused Carry Forward", "Unused Carry Forward"]

    }

  @Input('selectedEmployee') public selectedEmployee = new EventEmitter<{}>()
  @Input('componentHeight') public componentHeight: number
  @Input() public childApprovalData: Array<eleaveTypes.LeaveApplicationDatas> = []
  @Input('ParentSideData') public parentSideData: eleaveTypes.EleaveSummaryInfo = new eleaveTypes.EleaveSummaryInfoDefault()
  @Output('leaveSummary') public parentLeaveData: eleaveTypes.EleaveEmployeeDetailsHR = new eleaveTypes.EleaveEmployeeDetailsHRDefault() // Setting default value
  @Output('openChildEleave') public openChildEleave = new EventEmitter<boolean>()
  @Output('openleave') public openleave = new EventEmitter<boolean>()
  @ViewChild('child_eleave_component', { read: ViewContainerRef }) public child_eleave_component: ViewContainerRef
  @ViewChild('childMenu', { static: false, read: ElementRef }) childComponent: ElementRef
  @ViewChild('parentContainer', { static: false, read: ElementRef }) parentComponent: ElementRef
  @ViewChild('eleaveCategory') public eleaveCategory: NgselectEleaveCategoryComponent
  @ViewChild('advanceCategory') public advanceCategory: NgselectAdvanceCategoryComponent

  public propegateSelectedFilter: EventEmitter<eleaveTypes.LeaveDetails> = new EventEmitter()
  public subStorage: Array<Subscription> = []
  private $: JQueryStatic

  constructor(
    private jquery: jQueryServices,
    @Inject(Renderer2) private renderer: Renderer2,
    @Inject(ElementRef) public readonly el: ElementRef,
    @Inject(Platform) private readonly platform: Platform,
    @Inject(EleaveCategoryService) private readonly eleaveCategoryService: EleaveCategoryService,
    @Inject(EleaveApproverChangeDetactionService) private readonly changeDetaction: EleaveApproverChangeDetactionService
  ) {
    this.$ = jquery.getJqueryInstance()

    let advanceCategSub = eleaveCategoryService.$advanceCategoryChangeDetact.pipe(
      filter(category => category !== null)
    ).subscribe(category => {
      // console.log({ category });
      this.openClose()
      this.onChangeLeaveType(category)
      this.setBackgroundImageDynamic(category)
    })
    this.subStorage.push(advanceCategSub)

    let eleaveCategSub = this.eleaveCategoryService.$eleaveCategoryChangeDetact.pipe(
      filter(category => category !== null)
    ).subscribe(category => {
      // console.log({ category });
      this.closeDropDown()
      this.onChangeLeaveType(category)
      this.setBackgroundImageDynamic(category)
    })
    this.subStorage.push(eleaveCategSub)
  }


  ngOnInit(): void {
    this.VarObservable.next(true)
    this.VarObservable2.next(true)
    this.initializeData(this.parentLeaveData)
    this.subStorage.push(this.changeDetaction.leaveDetailsData$.subscribe(leaveDetails => {
      this.componentHeight = this.el.nativeElement.offsetHeight;
      if (leaveDetails != null) {
        this.leaveAllocationData = leaveDetails
        this.leaveTypesArray = Object.keys(leaveDetails.leave_allocation)
        this.selectedLeaveType = this.leaveTypesArray[0]
        this.leaveAllocationKeys = Object.keys(this.leaveAllocationData.leave_allocation[this.selectedLeaveType])
        this.selectedLeaveDetails = this.leaveAllocationData.leave_allocation[this.selectedLeaveType]
        ParentEleaveComponent.leaveAllocations.emit(this.selectedLeaveDetails)
        this.propegateSelectedFilter.emit(this.selectedLeaveDetails)
        console.log(this.selectedLeaveDetails)
      }
    }))

  }

  ngOnDestroy(): void {
    this.subStorage.forEach(data => {
      data.unsubscribe()
    })
    this.eleaveCategoryService.unsubscribeEleaveCategory()
  }

  ngAfterViewInit(): void {
    this.eleaveCategoryService.setEleaveCategorySelectInstance(this.eleaveCategory)
    this.eleaveCategoryService.setAdvanceCategorySelectInstance(this.advanceCategory)
  }

  openChild() {
    if (this.childOpened) {
      // Close child
      this.openChildEleave.next(false)
      this.openCloseJq()
      this.childOpened = false
    } else {
      // Open Child
      this.openChildEleave.next(true)
      this.openCloseJq()
      this.childOpened = true
    }
  }

  openCloseJq() {
    if ($(this.el.nativeElement.querySelectorAll('app-child-component')).children().is(':visible')) {
      $(this.el.nativeElement.querySelectorAll('app-child-component')).children().slideUp(200)
    } else {
      $(this.el.nativeElement.querySelectorAll('app-child-component')).children().slideDown(200).css("display", "flex")
    }
  }

  onChangeLeaveType(leaveType: string) {
    this.selectedLeaveType = leaveType
    if (this.selectedLeaveType in this.leaveAllocationData.leave_allocation) {
      this.selectedLeaveDetails = this.leaveAllocationData.leave_allocation[this.selectedLeaveType]
      ParentEleaveComponent.leaveAllocations.emit(this.selectedLeaveDetails)
      this.propegateSelectedFilter.emit(this.selectedLeaveDetails)
      this.componentHeight = this.el.nativeElement.offsetHeight;
    } else {
      this.propegateSelectedFilter.emit()
    }
    this.changeDetaction.setLeaveTypeChange(leaveType)
  }

  initializeData(data: eleaveTypes.EleaveEmployeeDetailsHR) {
    data.department === null ? this.department = 'NULL' : this.department = data.department
    data.reports_to === null ? this.reports_to = 'NULL' : this.reports_to = data.reports_to
    data.company === null ? this.companyName = 'NULL' : this.companyName = data.company
  }

  active(event) {
    this.popOverOpened = true
  }
  disabled(event) {
    this.popOverOpened = false
  }

  getUserDetailContainerHeight() {
    if (!this.checkAllClicked.value) {
      if (this.platform.height() <= 489) {
        return this.platform.height() * (49.069 / 100) + 'px'
      } else if (this.platform.height() > 489 && this.platform.height() <= 572) {
        return this.platform.height() * (58.420 / 100) + 'px'
      } else if (this.platform.height() > 572 && this.platform.height() <= 610) {
        return this.platform.height() * (61.282 / 100) + 'px'
      } else if (this.platform.height() > 610 && this.platform.height() <= 657) {
        return this.platform.height() * (63.581 / 100) + 'px'
      } else if (this.platform.height() > 657 && this.platform.height() <= 689) {
        return this.platform.height() * (64.693 / 100) + 'px'
      } else if (this.platform.height() > 689 && this.platform.height() <= 720) {
        return this.platform.height() * (65.887 / 100) + 'px'
      } else if (this.platform.height() > 720 && this.platform.height() <= 800) {
        return this.platform.height() * (68.729 / 100) + 'px'
      } else if (this.platform.height() > 800 && this.platform.height() <= 860) {
        return this.platform.height() * (70.580 / 100) + 'px'
      } else if (this.platform.height() > 860 && this.platform.height() <= 940) {
        return this.platform.height() * (73.371 / 100) + 'px'
      } else if (this.platform.height() > 940 && this.platform.height() <= 970) {
        return this.platform.height() * (71.103 / 100) + 'px'
      } else if (this.platform.height() > 970 && this.platform.height() <= 1080) {
        return this.platform.height() * (77.497 / 100) + 'px'
      } else if (this.platform.height() > 1080 && this.platform.height() <= 1200) {
        return this.platform.height() * (79.438 / 100) + 'px'
      } else if (this.platform.height() > 1200 && this.platform.height() <= 1600) {
        return this.platform.height() * (84.6875 / 100) + 'px'
      } else {
        return this.platform.height() * (79.001 / 100) + 'px'
      }
    } else {
      if (this.platform.height() <= 489) {
        return this.platform.height() * (41.094 / 100) + 'px'
      } else if (this.platform.height() > 489 && this.platform.height() <= 572) {
        return this.platform.height() * (50.028 / 100) + 'px'
      } else if (this.platform.height() > 572 && this.platform.height() <= 610) {
        return this.platform.height() * (52.908 / 100) + 'px'
      } else if (this.platform.height() > 610 && this.platform.height() <= 657) {
        return this.platform.height() * (57.341 / 100) + 'px'
      } else if (this.platform.height() > 657 && this.platform.height() <= 689) {
        return this.platform.height() * (58.452 / 100) + 'px'
      } else if (this.platform.height() > 689 && this.platform.height() <= 720) {
        return this.platform.height() * (65.887 / 100) + 'px'
      } else if (this.platform.height() > 720 && this.platform.height() <= 800) {
        return this.platform.height() * (68.871 / 100) + 'px'
      } else if (this.platform.height() > 800 && this.platform.height() <= 860) {
        return this.platform.height() * (66.161 / 100) + 'px'
      } else if (this.platform.height() > 860 && this.platform.height() <= 940) {
        return this.platform.height() * (69.967 / 100) + 'px'
      } else if (this.platform.height() > 940 && this.platform.height() <= 970) {
        return this.platform.height() * (70.486 / 100) + 'px'
      } else if (this.platform.height() > 970 && this.platform.height() <= 1080) {
        return this.platform.height() * (76.571 / 100) + 'px'
      } else if (this.platform.height() > 1080 && this.platform.height() <= 1200) {
        return this.platform.height() * (76.105 / 100) + 'px'
      } else if (this.platform.height() > 1200 && this.platform.height() <= 1600) {
        return this.platform.height() * (84.6875 / 100) + 'px'
      } else {
        return this.platform.height() * (79.001 / 100) + 'px'
      }
    }
  }

  openClose() {
    let class_none = this.childComponent.nativeElement
    if (this.$(class_none).is(':visible')) {
      this.$(class_none).css('diplay', 'block').slideUp(200)
      this.parentComponent.nativeElement.classList.remove('icon-go-btm')
    } else {
      this.$(class_none).css('diplay', 'block').slideDown(200)
      this.parentComponent.nativeElement.classList.add('icon-go-btm')
    }
  }

  closeDropDown() {
    let class_none = this.childComponent.nativeElement
    this.$(class_none).css('diplay', 'block').slideUp(200)
    this.parentComponent.nativeElement.classList.remove('icon-go-btm')
  }

  manipulateStatusColor() {
    if (this.parentLeaveData?.status != '' || this.parentLeaveData?.status != null) {
      if (this.parentLeaveData?.status == 'Active') {
        return 'approved'
      } else {
        return 'rejected'
      }
    }
  }
  
  setBackgroundImageDynamic(type) {
   
    switch (type) {
      case "Annual Leave":
        this.imageUrl = "./assets/img/icon/annual_l.png"
        break;

      case "Annual Leave (Replacement)":
        this.imageUrl = "./assets/img/icon/annual_l.png"
        break;

      case "Maternity Leave":
        this.imageUrl = "./assets/img/icon/maternity_l.png"
        break;

      case "Medical Leave":
        this.imageUrl = "./assets/img/icon/medical_l.png"
        break;

      case "Compassionate Leave":
        this.imageUrl = "./assets/img/icon/compassionate_l.png"
        break;

      case "Hospitalization":
        this.imageUrl = "./assets/img/icon/hospitalization_l.png"
        break;

      case "Marriage Leave":
        this.imageUrl = "./assets/img/icon/marriage_l.png"
        break;

      case "Calamity Leave":
        this.imageUrl = "./assets/img/icon/calamity_l.png"
        break;

      case "Paternity Leave":
        this.imageUrl = "./assets/img/icon/paternity_l.png"
        break;
      // case "Replacement Leave":
      //   this.imageUrl = "./assets/img/icon/leave-al.png"
      // break;
      default:
        this.imageUrl = "./assets/img/icon/leave_al.png"
        break;
    }
  }
  
  getEmployeeGenderImage(parentLeaveData: eleaveTypes.EleaveEmployeeDetailsHR) {
    if (parentLeaveData.gender == 'Male') {
      return "./assets/img/icon/man.png"
    } else if (parentLeaveData.gender == 'Female') {
      return "./assets/img/icon/woman.png"
    } else {
      return "./assets/img/profile-face-woman.jpg"
    }
  }

}
