import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileSelectorService {

  allowed_mime = ['image/png', 'image/jpeg', 'application/pdf']
  max_allowed_size = 10 * 1024 * 1024; // 10 MB

  constructor() { }

  fetchSelectedFile(event) {
    return new Promise((resolve, reject) => {
      if (event.target.files.length > 0) {
        if (this.allowed_mime.includes(event.target.files[0]['type'])) {
          // Check file size
          if (event.target.files[0].size < this.max_allowed_size) {
            let source = event.target.files[0]
            let filesize = ((event.target.files[0].size / 1024) / 1024).toFixed(4);
            let file = new FileReader()
            file.readAsDataURL(source)
            file.addEventListener('load', (data) => {
              resolve({
                'type': 'replace',
                'data': file.result,
                'name': source.name,
                'size': this.fileSizeType(event.target.files[0].size),
                'data_type': event.target.files[0]['type']
              })
            })

          } else {
            reject('Your file size is too large. Max 10MB')
          }
        } else {
          reject('File Type Not Allowed Please select only image and pdf file format.')
        }
      }
    })
  }
  fileSizeType(_size){
    var fSExt = new Array('Bytes', 'KB', 'MB', 'GB'),
    i=0;while(_size>900){_size/=1024;i++;}
    var exactSize = (Math.round(_size*100)/100)+' '+fSExt[i];
        console.log('FILE SIZE = ',exactSize);
        return exactSize
  }
  dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  openAttachment(source) {
    return new Promise((resolve, reject) => {
      // Detect blob
      try {
        var file = this.dataURLtoBlob(source['data'])
        var fileUrl = URL.createObjectURL(file)
        window.open(fileUrl)
        resolve(true)
      } catch (error) {
        reject('Unable to attach file. Please make sure the file is not protected')
      }
    })
  }

}
