import { Injectable } from '@angular/core'
import { ExpenseClaimDetail, ExpenseClaim } from '../../model/expense_claim.model'
import { ClassValidatorFormBuilderService, ClassValidatorFormGroup } from 'ngx-reactive-form-class-validator'
import { UntypedFormControl, Validators } from '@angular/forms'
import { ObjectMapper } from '../../core/object_mapper.service'

export type MetaData = {
    'ExpenseClaim': MetaDataObject<'schema', 'form'>,
    'ExpenseClaimDetail': MetaDataObject<'schema', 'form'>
}

type MetaDataObject<T, K> = {
    'schema': ExpenseClaim | ExpenseClaimDetail,
    'form'?: ClassValidatorFormGroup
}

// Responsible to preparing and validating the metadata for eclaim
@Injectable({
    providedIn: 'root'
})
export class EclaimMetaData {
    constructor(
        private form: ClassValidatorFormBuilderService,
        private db: ObjectMapper
    ) { }

    initMetaData(): MetaData {
        // Prepare required metadata for parent and child table
        let expense_claim: ExpenseClaim = this.db.getdocschema('ExpenseClaim')
        let expense_claim_details: ExpenseClaimDetail = this.db.getdocschema('ExpenseClaimDetail')
        return {
            'ExpenseClaim': {
                'schema': expense_claim,
            },
            'ExpenseClaimDetail': {
                'schema': expense_claim_details,
                'form': this.intiExpenseClaimDetailForm()
            }
        }
    }

    initMetaDataEdit(expenseDetail: ExpenseClaimDetail): MetaData {
        // Prepare required metadata for parent and child table
        // console.log(expenseDetail)
        let expense_claim: ExpenseClaim = this.db.getdocschema('ExpenseClaim')
        let expense_claim_details: ExpenseClaimDetail = this.db.getdocschema('ExpenseClaimDetail')
        return {
            'ExpenseClaim': {
                'schema': expense_claim,
            },
            'ExpenseClaimDetail': {
                'schema': expense_claim_details,
                'form': this.initEditExpenseClaimDetail(expenseDetail)
            }
        }
    }

    initEditExpenseClaimDetail(data: ExpenseClaimDetail): ClassValidatorFormGroup {
        let temp = {
            name: [data.name],
            expense_date: [data.expense_date],
            expense_type: [data.expense_type],
            vehicle_type: [data.vehicle_type],
            invoice_number: [data.invoice_number],
            amount: [data.amount],
            description: [data.description],
            file_attachment: [data.file_attachment]
        }

        if (data.mileage_to != undefined || data.mileage_to != null) {
            temp['mileage_to'] = [data.mileage_to]
            temp['mileage_from'] = [data.mileage_from]
            temp['mileage_return'] = [data.mileage_return]
            temp['mileage_distance'] = [data.mileage_distance]
            temp['transport_allowance'] = [data.disclaimed_transport_allowance]
            temp['deducted_transport_allowance'] = [data.deducted_transport_allowance]
            temp['mileage_description'] = [data.mileage_description]
        }
        return this.form.group(ExpenseClaimDetail, temp)
    }

    intiExpenseClaimDetailForm(): ClassValidatorFormGroup {
        return this.form.group(ExpenseClaimDetail, {
            name: [''],
            expense_date: [''],
            expense_type: [''],
            vehicle_type: [''],
            invoice_number: [''],
            amount: [''],
            description: [''],
            file_attachment: ['']
        })
    }

    // Set the listener on form page
    activateMilleageClaim(form: ClassValidatorFormGroup) {
        console.log(form, 'form');

        // Activate mileage claim
        if (form.contains('expense_type')) {
            // Fetch expense type
            if (form.controls['expense_type'].value == 'MILEAGE') {

                // Avoid replacing if already mapped
                if (form.contains('mileage_to') || form.contains('mileage_from')) {
                    return
                }

                form.addControl('mileage_to', new UntypedFormControl('', Validators.required), { emitEvent: false })
                form.addControl('mileage_from', new UntypedFormControl('', Validators.required), { emitEvent: false })
                form.addControl('mileage_return', new UntypedFormControl(''), { emitEvent: false })
                form.addControl('mileage_distance', new UntypedFormControl(''), { emitEvent: false })
                form.addControl('transport_allowance', new UntypedFormControl(''), { emitEvent: false })
                form.addControl('deducted_transport_allowance', new UntypedFormControl(''), { emitEvent: false })
                form.addControl('mileage_description', new UntypedFormControl(''), { emitEvent: false })


                form.get('mileage_to')
                form.get('mileage_from')
                form.get('mileage_return')
                form.get('mileage_distance')
                form.get('transport_allowance')
                form.get('deducted_transport_allowance')
                form.get('mileage_description')
                form.updateValueAndValidity({ emitEvent: false })
            } else {
                if (form.contains('mileage_to') || form.contains('mileage_from')) {
                    form.removeControl('mileage_to', { emitEvent: false })
                    form.removeControl('mileage_from', { emitEvent: false })
                    form.removeControl('mileage_return', { emitEvent: false })
                    form.removeControl('mileage_distance', { emitEvent: false })
                    form.removeControl('transport_allowance', { emitEvent: false })
                    form.removeControl('deducted_transport_allowance', { emitEvent: false })
                    form.removeControl('mileage_description', { emitEvent: false })

                    form.clearValidators()
                    form.updateValueAndValidity({ emitEvent: false })
                }
            }
        }
    }

}