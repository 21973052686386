import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { HeaderSubmoduleComponent } from './components/header-submodule/header-submodule.component';
import { SidemenuComponent } from './components/sidemenu/sidemenu.component';
import { DesktopModalComponent } from './components/desktop-modal/desktop-modal.component'
import { NgselectCompanySelectComponent } from './components/ngselect-company-select/ngselect-company-select.component'
import { NgselectEmployeeSelectComponent } from './components/ngselect-employee-select/ngselect-employee-select.component'
import { NgSelectModule } from '@ng-select/ng-select'
import { NgselectWeekendSelectComponent } from './components/ngselect-weekend-select/ngselect-weekend-select.component';
import { NgselectHalfdaySelectComponent } from './components/ngselect-halfday-select/ngselect-halfday-select.component';
import { RouterModule } from '@angular/router';
import { SwiperModule } from 'swiper/angular';
import { NgselectMonthSelectComponent } from './components/ngselect-month-select/ngselect-month-select.component';
import { NgselectYearSelectComponent } from './components/ngselect-year-select/ngselect-year-select.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgselectLeaveTypeSelectComponent } from './components/ngselect-leave-type-select/ngselect-leave-type-select.component';
import { NgselectDepartmentSelectComponent } from './components/ngselect-department-select/ngselect-department-select.component';
import { NgselectEleaveCategoryComponent } from './components/ngselect-eleave-category/ngselect-eleave-category.component';
import { NgselectAdvanceCategoryComponent } from './components/ngselect-advance-category/ngselect-advance-category.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';
import { NgselectDateSelectComponent } from './components/ngselect-date-select/ngselect-date-select.component';
import { NgselectMonthLeaveSelectComponent } from './components/ngselect-month-leave-select/ngselect-month-leave-select.component';
import { LeaveAlocationProgressBarComponent } from './components/leave-alocation-progress-bar/leave-alocation-progress-bar.component';
import { NgselectYearLeaveSelectComponent } from './components/ngselect-year-leave-select/ngselect-year-leave-select.component';
import { NgselectStatusLeaveSelectComponent } from './components/ngselect-status-leave-select/ngselect-status-leave-select.component';
import { NgselectTypeLeaveSelectComponent } from './components/ngselect-type-leave-select/ngselect-type-leave-select.component';
import { NgselectTypeNotificationSelectComponent } from './components/ngselect-type-notification-select/ngselect-type-notification-select.component';
import { AttendanceMonthWeekFilterComponent } from './components/ngselect-attendance-month-week-select/attendance-month-week-filter.component';
import { NgselectAttendanceMonthsYearSelectComponent } from './components/ngselect-attendance-month-year-select/ngselect-attendance-month-year-select.component';
import { NgselectMonthYearSelectComponent } from './components/ngselect-month-year-select/ngselect-month-year-select.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    HeaderComponent,
    HeaderSubmoduleComponent,
    SidemenuComponent,
    DesktopModalComponent,
    NgselectCompanySelectComponent,
    NgselectEmployeeSelectComponent,
    NgselectWeekendSelectComponent,
    NgselectHalfdaySelectComponent,
    NgselectMonthSelectComponent,
    NgselectYearSelectComponent,
    NgselectDepartmentSelectComponent,
    NgselectLeaveTypeSelectComponent,
    LeaveAlocationProgressBarComponent,
    NgselectEleaveCategoryComponent,
    NgselectAdvanceCategoryComponent,
    ProgressBarComponent,
    PieChartComponent,
    NgselectDateSelectComponent,
    NgselectMonthLeaveSelectComponent,
    NgselectYearLeaveSelectComponent,
    NgselectStatusLeaveSelectComponent,
    NgselectTypeLeaveSelectComponent,
    NgselectTypeNotificationSelectComponent,
    AttendanceMonthWeekFilterComponent,
    NgselectAttendanceMonthsYearSelectComponent,
    NgselectMonthYearSelectComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgSelectModule,
    SwiperModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    HeaderComponent,
    SidemenuComponent,
    InfiniteScrollModule,
    HeaderSubmoduleComponent,
    DesktopModalComponent,
    NgselectEmployeeSelectComponent,
    NgselectCompanySelectComponent,
    NgselectWeekendSelectComponent,
    NgselectHalfdaySelectComponent,
    NgselectMonthSelectComponent,
    NgselectYearSelectComponent,
    NgselectDepartmentSelectComponent,
    LeaveAlocationProgressBarComponent,
    NgselectLeaveTypeSelectComponent,
    NgselectEleaveCategoryComponent,
    NgselectAdvanceCategoryComponent,
    ProgressBarComponent,
    PieChartComponent,
    NgselectDateSelectComponent,
    NgselectYearLeaveSelectComponent,
    NgselectMonthLeaveSelectComponent,
    NgselectStatusLeaveSelectComponent,
    NgselectTypeLeaveSelectComponent,
    NgselectTypeNotificationSelectComponent,
    AttendanceMonthWeekFilterComponent,
    NgselectAttendanceMonthsYearSelectComponent,
    NgselectMonthYearSelectComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ShareableModule { }
