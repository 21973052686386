<div [@dropDownMenu]="leaveBalanceShow === true? 'open' : 'closed'">
    <div *ngIf="leaveBalanceShow" class="child-item pt-2 pb-2">
        <div class="table-responsive">
            <table class="table border table-hover">
                <thead>
                    <tr class="head-row text-center" style="font-size: smaller;">
                        <th [ngClass]="{'type-tl-radius': i == 0, 'type-tr-radius': i == (leaveAlocationheaders.length - 1) }"
                            scope="col" class="tbheader" *ngFor="let header of leaveAlocationheaders; let i = index">
                            {{header}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="body-row text-center" style="font-size: smaller;">
                        <th scope="row" class="type-bl-radius tbCfdata">
                            {{leaveDetails.allocation_details[0].leave_type}} </th>
                        <th scope="row" class="tbCfdata">{{leaveDetails.leaves_allocated}}</th>
                        <th scope="row" class="tbCfdata">{{leaveDetails.carry_forward}}</th>
                        <th scope="row" class="tbCfdata">{{leaveDetails.total_leaves}}</th>
                        <th scope="row" class="tbCfdata">{{leaveDetails.leaves_taken}}</th>
                        <th scope="row" class="tbCfdata">{{leaveDetails.carry_forward_taken}}</th>
                        <th scope="row" class="tbCfdata">{{leaveDetails.expired_carry_forward}}</th>
                        <th scope="row" class="tbCfdata">{{leaveDetails.remaining_leaves}}</th>
                        <th scope="row" class="type-br-radius tbCfdata">{{leaveDetails.prorated_leaves}}</th>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div [@dropDownMenu]="leaveBalanceEmpty === true? 'open' : 'closed'" class="pb-3">
    <div *ngIf="leaveBalanceEmpty" class="child-item" style="color: red;">
        <i><small>No leave allocated for the leave type selected</small></i>
    </div>
</div>