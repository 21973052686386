import { Injectable } from '@angular/core'
import { ExpenseClaim, ExpenseClaimDetail } from '../model/expense_claim.model'
import { Auth, Login, User, UserProfile } from '../model/login.model'
import { Eleave } from '../model/eleave.model'
import { validate, validateSync, ValidatePromise } from 'class-validator'
import { StorageNoSql } from './storage-nosql.service'
import { isEmpty } from 'lodash'

export const schema = { ExpenseClaim, ExpenseClaimDetail, Auth, Login, UserProfile }
export const schema_map: Object = {
    'ExpenseClaim': ExpenseClaim,
    'ExpenseClaimDetail': ExpenseClaimDetail,
    'Auth': Auth,
    'Login': Login,
    'User': User,
    'UserProfile': UserProfile,
    'Eleave': Eleave
}
export const cached_default_schema: Object = {
    'ExpenseClaim': new ExpenseClaim(),
    'ExpenseClaimDetail': new ExpenseClaimDetail(),
    'Auth': new Auth(),
    'Login': new Login(),
    'User': new User(),
    'UserProfile': new UserProfile(),
    'Eleave': new Eleave()
}

@Injectable({
    providedIn: 'root'
})
export class ObjectMapper {
    private db: any
    constructor(private storage: StorageNoSql) {
        this.db = this.storage.getDbInstance()
    }

    getdocschema(collection) {
        if (schema_map.hasOwnProperty(collection)) {
            return new schema_map[collection]
        }

        const errorMessage: ErrorEventInit = {
            error: new Error('Schema Not Found'),
            message: "Requested schema is not found. Please initilize the schema.",
            lineno: 23,
            filename: 'ObjectMapper.ts'
        }

        throw new ErrorEvent('ObjectMapper', errorMessage)
    }

    async validate_doc(collection, doc, sync = false) {
        if (sync) {
            validateSync(doc)
            return true
        } else {
            validate(collection, doc).then((doc) => {
                return true
            }).catch((err) => {
                throw new ErrorEvent('ObjectMapper_Validator', err)
            })
        }
    }

    async getOneDoc(collection, selector = {}, options = {}, cached = false) {
        // Can skip validation for doctype
        // Assume all schema is initilized before using
        let collectionInstance: any = await this.storage.fetchCollectionInstance(collection)
        return collectionInstance.findOne(selector, options).fetch((data) => {
            return data
        }, (error) => {
            throw new ErrorEvent('ObjectMapper', error)
        })
    }

    async getdoc(collection, selector = {}, options = {}, cached = false) {
        // Can skip validation for doctype
        // Assume all schema is intialized before using
        let collectionInstance: any = await this.storage.fetchCollectionInstance(collection)
        return collectionInstance.find(selector, options).fetch((data) => {
            return data
        }, (error) => {
            throw new ErrorEvent('ObjectMapper', error)
        })
    }

    async updatedoc(collection, doc, base = {}, cached = false) {
        // Assume all objects are instances of class validators
        if (doc instanceof Array) {
            // Validate each doc on the array
            doc.forEach((doc) => {
                this.validate_doc(collection, doc)
            })
        } else {
            this.validate_doc(collection, doc)
        }

        let collectionInstance: any = await this.storage.fetchCollectionInstance(collection)

        if (isEmpty(base)) {
            collectionInstance.upsert(doc, (data) => {
                // Succesfull append
                return data
            }, (err) => {
                // Error appending document
                throw new ErrorEvent('ObjectMapperUpdateDoc', err)
            })
        } else {
            collectionInstance.upsert(doc, base, (data) => {
                // Succesfull append
                return data
            }, (err) => {
                // Error appending document
                throw new ErrorEvent('ObjectMapperUpdateDoc', err)
            })
        }

    }

    async removedoc(collection, doc, cached = false) {
        await this.storage.removeObjectInCollection(collection, doc).catch((err) => {
            throw new ErrorEvent('ObjectMapperRemoveDoc', err)
        })

        if (cached) {
            // Remove item from cached object
            await this.cachedIntilization(collection, doc)
        }

        return true
    }

    async cachedIntilization(collection, selector = {}) {
        // Function will fetch items from db and store in session scoped variable(Object or Session Storage)
        // Current implementation will focus on object
        // Only one doc is allowed per cached variable
        let dataInstance = await this.getOneDoc(collection, selector)
        // Validate before putting into cache
        if (dataInstance) {
            await this.validate_doc(collection, dataInstance)
        }

        return dataInstance
    }
}