<div class="text-left">
  <div class="selectEmployee"><b>Select Employee</b></div>
  <div class="relative">
    <ng-select style="border-radius: 20px;" #employeeSelect [items]="employee$|async" bindLabel="description"
      [disabled]="disableEmployee" [multiple]="true" [closeOnSelect]="false" [clearable]="true"
      [loading]="employeeLoading" [typeahead]="employeeInput$" typeToSearchText="Employee Name" [virtualScroll]="true"
      [(ngModel)]="selectedEmployee" (change)="onSelectionChange($event)" (add)="onItemAdded($event)"
      dropdownPosition="auto" [clearSearchOnAdd]="true" (close)="onDropdownClose(employeeSelect)"></ng-select>
    <i class="fa fa-search"></i>
  </div>
</div>