<div class="text-left">
  <div class="selectCompany"><b>Select Company</b></div>
  <div class="relative">
    <ng-select style="border-radius: 20px;" #companySelect [items]="company$ | async" bindLabel="value"
      [disabled]="disableCompany" [multiple]="true" [closeOnSelect]="false" [loading]="companyLoading"
      [typeahead]="companyInput$" typeToSearchText="Company Name" [virtualScroll]="true" [(ngModel)]="selectedCompany"
      (change)="onSelectionChange($event)" (add)="onItemAdded($event)" dropdownPosition="auto" [clearSearchOnAdd]="true"
      (close)="onDropdownClose(companySelect)"></ng-select>
    <i class="fa fa-search" style="right: 18px !important;"></i>
  </div>
</div>