// - Contains observable and exclusive to only side menu
// - Takes instances of a components
// - Assumes services are restrictred with module - Uses tokenatization to init
// One to One between component relationship

import { Injectable, ElementRef } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({
    providedIn: 'root'
})
export class SideMenuEmitService {
    public state = new Subject<boolean>()
    public selectedItem: Subject<string> = new Subject<string>();
    public selectedChildItem: Subject<string> = new Subject<string>();
    // Make sure component is registered
    public components: ElementRef<any>

    constructor() { }

    registerComponent(comp: ElementRef<any>) {

        if (this.components == comp) {
            throw 'Service already registered to a component'
        }

        this.components = comp
        return this.state.asObservable()
    }

    fetchObserver(comp: ElementRef<any>) {
        if (this.components == null || this.components == undefined) {
            throw 'Please register a component to service'
        }

        if (comp == this.components) {
            return this.state.asObservable()
        }
    }

    emitEvent(data: boolean) {
        this.state.next(data)
    }
}