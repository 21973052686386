import { ChangeDetectorRef, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpServiceService } from '../core/http-service.service'
import { StorageNoSql } from '../core/storage-nosql.service'
import { environment } from '../env/environment'
import { GlobalVariables } from '../core/globalvar.service'
import { isEqual } from 'lodash'

import { ObjectMapper } from '../core/object_mapper.service' // Object mapping service
import { AuthType, UserType } from '../model/init.model' // Type declaration


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  isLoggedIn: any = false;
  apiUrl: string = environment['apiUrl']
  authUser: any = [];
  db: any
  collection: string = 'Auth'
  dependancy: string = 'Claims'

  constructor(
    private http: HttpClient,
    private objectMap: ObjectMapper,
    private storageNosql: StorageNoSql,
    private globalvar: GlobalVariables,
    private httpser: HttpServiceService,
  ) {
    this.db = storageNosql.getDbInstance()
  }

  login(param = {}, headers = {}) {
    return new Promise(async (resolve, reject) => {
      this.http.post(this.apiUrl + '/auth/login', param, headers).subscribe({
        next: async (data) => {
          if (data['code'] == 200) {
            await this.setCredentialsVariables(data)
            resolve(true)
          } else {
            reject(false)
          }
        },
        complete: () => { console.log('completed login') },
        error: (error) => { reject(error) }
      })
    })
  }

  async setCredentialsVariables(responce) {
    let auth: AuthType = this.objectMap.getdocschema('Auth')

    auth.access_token = responce['data']['access_token']
    auth.refresh_token = responce['data']['refresh_token']
    console.log({ auth });

    await this.objectMap.updatedoc('Auth', auth, {}, true)
    await this.globalvar.setAuthData(auth)
  }

  // Check token authencity - Handled on the backend for this currently
  getLogin() {
    return new Promise((resolve, reject) => {
      this.storageNosql.getDbInstance().Auth.findOne({ schema_doctype: 'Auth' }, (base) => {
        if (base == null) {
          // Show pop up
        }
        resolve(true)
      }, () => {
        reject('Failed to get user collection information')
      })
    })
  }

  getUserRole() {
    return new Promise(async (resolve, reject) => {
      this.db = this.storageNosql.getDbInstance()
      this.httpser.get('/users/getUserRole').then((data: any) => {
        // console.log(data)
        // Maintain only one user doc
        let user: UserType = this.objectMap.getdocschema('User')

        user.user_role = data['message']['role']
        user.employee_id = data['message']['employee_id']
        user.employee_name = data['message']['employee_name']
        user.reports_to_id = data['message']['reports_to']
        user.reports_to_name = data['message']['reports_to_username']
        user.company = data['message']['company']
        user.is_leave_approver = data['message']['is_leave_approver']
        user.is_hr = data['message']['is_hr']
        // user.is_hr
        // console.log(this.db)
        this.db.User.findOne({ schema_doctype: 'User' }, (base) => {
          // console.log(base)
          if (base == null) {
            // Set default base
            base = user
          } else {
            // Set User ID to base ID
            user._id = base._id
          }

          if (isEqual({ ...user }, base)) {
            this.globalvar.setUserdata(user)
            resolve(data)
          } else {
            this.db.User.upsert(user, base, () => {
              console.log('here')
              this.globalvar.setUserdata(user)
              resolve(data)
            }, () => {
              reject(false)
            })
          }

        })
      }).catch(error => {
        console.log(error)
        this.db.User.findOne({ schema_doctype: 'User' }, (base) => {
          if (base == null) {
            reject()
          }
        })
      })
    })
  }
}
