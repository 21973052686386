import { Component, OnInit, Input, EventEmitter, OnDestroy } from '@angular/core';
import * as eleaveTypes from 'projects/fortunebit-staffhub/src/eleave/services/typings/eleave-api.typings'
import { DropDownAnimation } from 'fortunebit-staffhub/src/animations/animation';
import { Subscription } from 'rxjs'


@Component({
    selector: 'app-eleave-allocation-table',
    templateUrl: './eleave-allocation-table.component.html',
    styleUrls: ['./eleave-allocation-table.component.scss'],
    animations: [DropDownAnimation]
})
export class EleaveAllocationTableComponent implements OnInit, OnDestroy {
    @Input('allocationDetails') public allocationDetails: EventEmitter<eleaveTypes.LeaveDetails>
    public carryForwardDetails = {}
    // public leaveDetails = {}
    public leaveDetails: eleaveTypes.LeaveDetails
    public isCarryForward = false
    public isLeaveDetails = false
    public leaveBalanceShow: boolean = false
    public leaveBalanceEmpty: boolean = false
    public leaveAlocationheaders: string[] = ['Leave Type', 'Leaves Allocated', 'Carry Forward', 'Total Allocated Leaves', 'Used Leaves', 'Used CF Leaves', 'Expired CF Leaves', 'Available Leaves', 'Pro-rated Leaves'];
    public subAllocationDetails: Subscription
    constructor() { }

    ngOnInit(): void {
        this.subAllocationDetails = this.allocationDetails.subscribe(data => {
            // console.log({ data });
            (data != null || data != undefined) ? [this.leaveBalanceShow = true, this.leaveBalanceEmpty = false] : [this.leaveBalanceShow = false, this.leaveBalanceEmpty = true]
            this.leaveDetails = data
            this.carryForwardDetails = {}
            this.isCarryForward = false
            this.isLeaveDetails = false
            if (data) {
                this.destructData(data)
            }
        })
    }

    ngOnDestroy(): void {
        this.subAllocationDetails.unsubscribe()
    }

    destructData(data: eleaveTypes.LeaveDetails) {
        console.log(data)
        data.allocation_details.forEach(e => {
            if (e.is_carry_forward == 1) {
                this.carryForwardDetails = e
                this.isCarryForward = true
            } else {
                // this.leaveDetails = e
                this.isLeaveDetails = true
            }
        })

        if (this.carryForwardDetails) {
            this.carryForwardDetails['carry_forward_taken'] = data.carry_forward_taken
            this.carryForwardDetails['carry_foward'] = data.carry_forward
            this.carryForwardDetails['expired_carry_forward'] = data.expired_carry_forward
            this.carryForwardDetails['progress_left'] = (data.unused_carry_forward - data.expired_carry_forward).toString()
            this.carryForwardDetails['progress_percent'] = `${((data.unused_carry_forward - data.expired_carry_forward) / data.carry_forward) * 100}%`
            this.carryForwardDetails['minProgress'] = 0
            this.carryForwardDetails['maxProgress'] = data.carry_forward
            this.carryForwardDetails['leave_type'] = 'Carry Forward'
        }
        // console.log(this.carryForwardDetails)
        if (this.leaveDetails) {
            this.leaveDetails = { ...this.leaveDetails, ...data }
            this.leaveDetails['progress_left'] = data.remaining_leaves.toString()
            this.leaveDetails['progress_percent'] = `${(data.remaining_leaves / data.total_leaves) * 100}%`
            this.leaveDetails['minProgress'] = 0
            this.leaveDetails['maxProgress'] = data.total_leaves
        }
    }
}
