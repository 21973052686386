import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgOption, NgSelectComponent } from '@ng-select/ng-select';
import { EleaveApproveService } from 'desktop-app/src/app/eleave/eleave-approve/services/eleave-approve.service';
import { EleaveCore } from 'fortunebit-staffhub/src/eleave/services/eleave-core';
import { Observable, Subject } from 'rxjs';

export interface Year {
  value: number
  metadata?: NgOption
}

@Component({
  selector: 'app-ngselect-year-select',
  templateUrl: './ngselect-year-select.component.html',
  styleUrls: ['./ngselect-year-select.component.scss']
})
export class NgselectYearSelectComponent implements OnInit {

  public disableCompany: boolean = false

  year$: Observable<Year[]>[]
  yearLoading = false
  yearInput$ = new Subject<string>()
  newlyAdded: Year[] = []

  @Input('selectedYear') public selectedYear: Year
  @Input('replaceSelected') public replaceSelected = new EventEmitter<Array<Year>>()
  @Input('removeSelected') public removeSelected = new EventEmitter<string>()
  @Output('selectedYearOutput') public selectedYearOutput = new EventEmitter<Year>()
  @ViewChild("yearSelect") public yearSelect: NgSelectComponent


  constructor(
    @Inject(EleaveCore) public eleaveCore: EleaveCore,
    @Inject(EleaveApproveService) private readonly eleaveApproveService: EleaveApproveService
  ) { }

  ngOnInit() {
    const years = this.eleaveApproveService.loadYears(2)
    this.selectedYear = years[years.length - 1]
    this.selectedYearOutput.emit(this.dataInterceptor(this.selectedYear))
    this.eleaveApproveService.setCreationYear(this.selectedYear.value)
  }

  ngAfterViewInit(): void {
    this.year$ = this.eleaveApproveService.loadYears(2)
    this.trackYearSelected()
  }

  dataInterceptor(data: any) {
    if (data !== undefined) {
      data.metadata = this.yearSelect.itemsList.selectedItems.find((val) => {
        if (val.value['value'] == data.value) {
          return true
        }
        return false
      })
      return data
    } else {
      return 0
    }
  }

  trackYearSelected() {
    this.yearSelect.changeEvent
      .subscribe(data => {
        this.selectedYearOutput.emit(this.dataInterceptor(data))
      })
  }

  onSelectionChange(event) {
    // this.companySelect.close()
  }

  onItemAdded(event) {
    // this.companySelect.close();
  }

  onDropdownClose(yearSelect: any) {
    // yearSelect.clearSearch();
  }

}
