import { Injectable, } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Subscribable, Subscription, Observable } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import SwiperCore from 'swiper'
import { cs_log } from '@handler/logger.handler'
import { NgOption } from "@ng-select/ng-select"

export interface MonthYear {
  selectedMonthString: string
  selectedMonthNum: number
  selectedYear: number
}

export interface MonthsList {
  month_of_leave: string,
  metadata?: NgOption
}

export interface YearList {
  year_of_leave: string,
  metadata?: NgOption
}

@Injectable({
  providedIn: 'root'
})

export class AttendanceListFilterServices {
  public baseStruct: MonthYear
  public momentInstance = moment()
  public monthList = moment.months()
  public currentMonthNum: number = 0
  public currentMonthStr: string
  public emptyAttendanceList: boolean = false
  public currentYear: number = this.momentInstance.year()

  public $monthFilterChangeDetection: BehaviorSubject<MonthYear>
  public _monthFilterSubscription: Observable<MonthYear>

  constructor() {
    this.refreshMonthYear()
    this.getCurrentMonth()
    this.buildBaseStruct()
    this.$monthFilterChangeDetection = new BehaviorSubject(<MonthYear>this.baseStruct)
    this._monthFilterSubscription = this.$monthFilterChangeDetection.asObservable()
    cs_log(this)
  }

  buildBaseStruct() {
    this.baseStruct = {
      selectedMonthNum: this.currentMonthNum + 1,
      selectedMonthString: this.currentMonthStr,
      selectedYear: this.currentYear
    }
  }


  refreshMonthYear() {
    this.currentMonthNum = this.momentInstance.month()
    this.getCurrentMonth()
    this.currentYear = this.momentInstance.year()
  }

  getCurrentMonth() {
    this.currentMonthStr = this.monthList[this.currentMonthNum]
  }

  selectedMonth(monthIndex: number) {
    console.log({ monthIndex })
    if (monthIndex != -1) {
      this.currentMonthNum = monthIndex
      this.getCurrentMonth()
      this.buildBaseStruct() // Send request to service
    } else {
      this.refreshMonthYear()
      this.buildBaseStruct() // Send request to service
      // this.applyFilter()
    }
  }

  selectedYear(year: number) {
    console.log({ year });
    if (year != 0) {
      this.currentYear = year
      this.buildBaseStruct()
    } else {
      this.refreshMonthYear()
      this.buildBaseStruct()
    }
  }

  applyFilter() {
    this.emptyAttendanceList = false
    this.emitChanges()
  }

  emitChanges() {
    this.$monthFilterChangeDetection.next(this.baseStruct)
  }

  setMarginTop(height: number) {
    if (height <= 660) {
      return '0'
    } else if (height > 660 && height <= 740) {
      return '0'
    } else if (height > 740 && height <= 778) {
      return '5'
    } else if (height > 778 && height <= 799) {
      return '5'
    } else {
      return '0'
    }
  }

  setHeight(height: number) {
    if (height <= 567) {
      return 14
    } else if (height > 567 && height <= 656) {
      return 12
    } else if (height > 656 && height <= 660) {
      return 12
    } else if (height > 660 && height <= 667) {
      return 14
    } else if (height > 667 && height <= 740) {
      return 11
    } else if (height > 740 && height <= 778) {
      return 10
    } else if (height > 778 && height <= 799) {
      return 10
    } else if (height > 799 && height <= 812) {
      return 12
    } else if (height > 812 && height <= 844) {
      return 11
    } else if (height > 844 && height <= 851) {
      return 9
    } else if (height > 851 && height <= 896) {
      return 10
    } else if (height > 896 && height <= 915) {
      return 9
    } else {
      return 0
    }
  }

}
