<div class="ba-balance-inner align-self-center card-style align-top parent-claim parent-content"
  style="display: inline-block;padding-bottom: 5px;border-radius: 20px 20px 20px 20px; height: 1125px;">

  <div class="icon icon-eclaim" style="padding:0px; margin:0px; background:none;">
    <div class="header-user photo_renderer"
 [style.backgroundImage]="'url(' + getEmployeeGenderImage(parentLeaveData) + ')'"
      style="margin:auto; background-repeat: no-repeat; background-position: center center; background-size:cover; width:100%; height:100%; border-radius:6666px; image-rendering: -webkit-optimize-contrast; border:1px solid #ccc;">
    </div>
  </div>

  <div>
    <div style="overflow:hidden;" class="modal fade" id="all-add-reason" tabindex="-1"
      aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style="border-radius: 25px; padding: 18px; border: 3px solid #3a3a3a8a;">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add Remark: </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body add_reason_form">
            <label for="reason">Reason</label>
            <textarea [(ngModel)]="reason" type="text" class="form-control" autocomplete="false"
              aria-describedby="distanceHelp" required="true"></textarea>
            <i><small *ngIf="reason === ''" style="color: red;">Required</small></i>
          </div>
          <div class="form-group basic relative modal-footer d-flex justify-content-center">
            <button [ngClass]="{'btn-disabled': reason === ''}" [disabled]="reason === ''" type="button"
              class="btn-c btn-blue btn-lg w-100 noicon pl5" data-bs-dismiss="modal">Save
              Remark</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <app-leave-info-popup></app-leave-info-popup>
  </div>

  <div data-target="" style="position:relative; padding-left: 54px; padding-right: 0px; cursor: pointer;">
    <div *ngIf="noLeaveAllocation == false"
      style="display: flex; align-items: center; margin-top: 10px; margin-right: 20px; justify-content: space-between;">
      <div style="display: flex;">
        <h5 class="allocation-title">Applied Leave Types</h5>
        <svg id="leave_icon" _ngcontent-mnm-c235="" xmlns="http://www.w3.org/2000/svg" data-name="Design Convert"
          viewBox="0 0 64 64">
          <defs _ngcontent-mnm-c235="">
            <style _ngcontent-mnm-c235=""></style>
          </defs>
          <title _ngcontent-mnm-c235=""></title>
          <path _ngcontent-mnm-c235="" d="M55,28H34a1,1,0,0,1,0-2H55a1,1,0,0,1,0,2Z"></path>
          <path _ngcontent-mnm-c235=""
            d="M28,57a1,1,0,0,1-.45-.11L8.66,47.45A3,3,0,0,1,7,44.76V10a3,3,0,0,1,3-3h9a1,1,0,0,1,0,2H11.34l17.09,8.1A1,1,0,0,1,29,18V56a1,1,0,0,1-.47.85A1,1,0,0,1,28,57ZM9,10.11V44.76a1,1,0,0,0,.55.9L27,54.38V18.63Z">
          </path>
          <path _ngcontent-mnm-c235=""
            d="M47,37a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L54.59,27l-8.3-8.29a1,1,0,0,1,1.42-1.42l9,9a1,1,0,0,1,0,1.42l-9,9A1,1,0,0,1,47,37Z">
          </path>
          <path _ngcontent-mnm-c235="" d="M37,47H28a1,1,0,0,1,0-2h9a1,1,0,0,0,1-1V36a1,1,0,0,1,2,0v8A3,3,0,0,1,37,47Z">
          </path>
          <path _ngcontent-mnm-c235=""
            d="M39,19a1,1,0,0,1-1-1V10a1,1,0,0,0-1-1H15a1,1,0,0,1,0-2H37a3,3,0,0,1,3,3v8A1,1,0,0,1,39,19Z"></path>
        </svg>
      </div>
      <div *ngIf="parentLeaveData?.status && parentLeaveData?.status != ''"
        style="position: absolute; right: 15px; z-index: 1; margin-bottom: 50px;">
        <span style="padding-left: 20px; padding-right: 20px; font-weight: 900;"
          ngClass="{{parentLeaveData?.status == 'Active'? 'active-box-shadow' : 'other-box-shadow'}}"
          class="tag filter-tag list-tag {{manipulateStatusColor()}}">
          {{parentLeaveData?.status}}
        </span>
      </div>
    </div>

    <div class="leave_type">
      <div *ngIf="noLeaveAllocation == false" class="ba-balance-inner mw-100 leaveTypes" data-contentfilter="pending">
        <div #parentContainer class="icon-go-right icon-animate ng-star-inserted" style="right: -1px;top:24px"
          (click)="openClose()">
          <a class="">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#"
              xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
              xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
              xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns:svg="http://www.w3.org/2000/svg"
              height="700" id="svg8" version="1.1" viewBox="0 0 185.20832 185.20832" width="700"
              style="max-width: 30px;" class="">
              <defs id="defs2" class=""></defs>
              <g id="layer1" transform="translate(244.17261,202.68451)" class="">
                <g id="g2197" transform="translate(-287.99336,0.8261165)" class="">
                  <path d="M 184.0267,-110.90647 88.823128,-22.978113" id="path1803-1"
                    style="stroke-width: 9.26041603; stroke-linecap: round; stroke-linejoin: round; stroke-miterlimit: 4; stroke-dasharray: none; stroke-opacity: 1;"
                    class=""></path>
                  <path d="M 184.0267,-110.90647 88.823128,-198.83482" id="path1805-1"
                    style="stroke-width: 9.26041603; stroke-linecap: round; stroke-linejoin: round; stroke-miterlimit: 4; stroke-dasharray: none; stroke-opacity: 1;"
                    class=""></path>
                </g>
              </g>
            </svg>
          </a>
        </div>
        <app-ngselect-eleave-category #eleaveCategory></app-ngselect-eleave-category>

      </div>
      <div *ngIf="noLeaveAllocation == false" #childMenu
        style="padding: 12px;position:absolute;background: rgb(255, 255, 255);top: 86px;right: 15px;z-index: 99;display:none;border-bottom-right-radius: 20px;border-bottom-left-radius: 20px;box-shadow: rgb(212 209 229) 0px 15px 20px 0px;">
        <app-ngselect-advance-category #advanceCategory></app-ngselect-advance-category>
      </div>
    </div>

    <div class="row" *ngIf="noLeaveAllocation == false">
      <div *ngIf="noLeaveAllocation == false" class="col-12" style=" padding-left:0px; border-top: 1px dashed #b7b9ef; border-bottom:1px dashed #b7b9ef">
        <h5 class="ledger-title">Ledger Details</h5>
        <progress-bar [allocationDetails]="propegateSelectedFilter"></progress-bar>
      </div>
      <div class="col-12" style=" padding-left:0px;">
        <div style="display: flex; align-items: center; margin-top: 10px;">
          <div style="display: flex;">
            <h5 class="allocation-title">Allocation Details</h5>
            <svg (mouseenter)="active($event)" (mouseleave)="disabled($event)" id="info_icon"
              xmlns="http://www.w3.org/2000/svg" data-name="Design Convert" viewBox="0 0 64 64">
              <defs></defs>
              <path
                d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z" />
            </svg>
          </div>
        </div>
        <app-eleave-allocation-table [allocationDetails]="propegateSelectedFilter"></app-eleave-allocation-table>
      </div>
    </div>
    <div *ngIf="popOverOpened == true" id="popOverAllocation"
      style="position: absolute;z-index: 12;width: 65%;font-size: 10px;top: 179px;right: 96px;border-radius: 12px;margin: 0 !important;">


    </div>

    <div *ngIf="noLeaveAllocation == true" style="display: flex;align-items: center;margin-top: 10px;">
      <div class="empty-leave-allocation">
        <h5 style="color: white;">There is no leave allocation yet</h5>
      </div>
    </div>
  </div>
  <div class="child-filter">
    <div class="type-container">
      <app-eleave-type-status></app-eleave-type-status>
    </div>

  </div>
  <div *ngIf="childOpened" class="" data-moredetails="eclaim" style="display: block;">
    <div class="child_data" [style.overflow-x]="overflowX" [style.overflow-y]="overflowY"
      style="background:rgb(255, 255, 255); max-height: 600px;padding-bottom: 40px;">
      <ng-template #child_eleave_component></ng-template>
      <div *ngIf="scrollLoading" class="loader">
        <span class="">Loading...</span>
      </div>
    </div>
  </div>
</div>
<div>
  <app-side-highlighted [ParentSideData]="parentSideData" (selectedEmployee)="selectedEmployee"
    [childApprovalData]="childApprovalData"></app-side-highlighted>
</div>