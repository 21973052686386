import {
    Validate,
    validateOrReject,
    Allow,
    Min, Max, isString, IsDate,
    IsBoolean, IsNotEmpty, IsDecimal,
    ValidateIf
} from 'class-validator'

import { AllowedClaimAmount } from './custom_validation/amount-validation'
import { ExpenseDateValid } from './custom_validation/expense-date-validation'


export class ExpenseClaim {
    @Allow()
    @IsDate()
    naming_series: string = 'EXP'
    @Allow()
    name: string
    @Allow()
    employee: string
    @IsNotEmpty()
    expenses: [ExpenseClaimDetail]
    @Allow()
    taxes: string
    @Allow()
    grand_total: number
    @Allow()
    total_claimed_amount: number
    @Allow()
    posting_date: string
    @Allow()
    schema_doctype: string = 'Expense Claim'
    @Allow()
    status: string
    @Allow()
    workflow_state: string
    @Allow()
    action: string
    @Allow()
    from_server: string
    @Allow()
    is_imported: number = 0
}

export interface ExpenseClaimInter {
    naming_series: string
    name: string
    employee: string
    expenses: [ExpenseClaimDetail]
    taxes: string
    grand_total: number
    total_claimed_amount: number
    posting_date: string
    schema_doctype: string
}

export interface ExpenseClaimDetailsMileageInter {
    mileage_to: string
    mileage_from: string
    return_trip: Boolean
    transport_allowance: number
    deducted_transport_allowance: number
    mileage_distance: string
}

export class ExpenseClaimDetailsMileage {
    mileage_to: string
    mileage_from: string
    return_trip: Boolean
    transport_allowance: number
    deducted_transport_allowance: number
    mileage_distance: string
}

export class ExpenseClaimDetail {
    @Allow()
    name: string
    @Allow()
    mileage_to: string
    @Allow()
    mileage_from: string
    @IsNotEmpty()
    @Validate(ExpenseDateValid)
    expense_date: string
    @IsNotEmpty()
    expense_type: string
    @ValidateIf(o => o.expense_type === 'MILEAGE')
    @IsNotEmpty({ message: 'Vehicle type must not be empty if expense type is MILEAGE' })
    vehicle_type: string
    @Allow()
    invoice_number: string
    @Allow()
    description: string
    @Allow()
    mileage_distance: string
    @Allow()
    transport_allowance: number = 0
    @Allow()
    deducted_transport_allowance: number = 0
    @Allow()
    disclaimed_transport_allowance: number = 0
    @IsNotEmpty()
    @Validate(AllowedClaimAmount)
    amount: number = 0
    @Allow()
    sanctioned_amount: number
    @Allow()
    status: string = 'Local'
    @Allow()
    mileage_return: Boolean = false
    @Allow()
    attachment: any = null
    @Allow()
    schema_doctype: string = 'Expense Claim Detail'
    @Allow()
    mileage_description: string
    @Allow()
    action: string
    @Allow()
    file_attachment: string
    @Allow()
    is_imported: number = 0
}

export interface ExpenseClaimDetailInter {
    name: string
    mileage_to: string
    mileage_from: string
    expense_date: string
    expense_type: string
    vehicle_type: string
    invoice_number: string
    description: string
    mileage_distance: string
    transport_allowance: number
    deducted_transport_allowance: number
    amount: string
    sanctioned_amount: string
    status: string
    schema_doctype: string
}