import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as attendanceTypes from 'fortunebit-staffhub/src/eattendance/services/typings/eattendance-api-typings';
@Injectable({
  providedIn: 'root'
})
export class ChangeDetectionService {

  public $eattendanceListRefresh = new Subject<boolean>()
  public $eattendanceApprovalListRefresh = new Subject<boolean>()
  public $trigerApprovalListRefresh = new Subject<boolean>()
  public $flexiEmployeeChangeDetaction = new Subject<attendanceTypes.FlexiUserApprovalData>()
  public $flexiEmployeeFlexiListChangeDetaction = new Subject<boolean>()
  public refreshAfterAction = new Subject<boolean>();
  public $flexiCheckAllApprove = new Subject<boolean>()
  public $flexiIndividualCheckbox = new Subject<boolean>()
  public $clearAllEmplComp = new Subject<boolean>()
  public $flexiEmployeeCompanySelect = new Subject<boolean>()
  public $refreshPage = new Subject<boolean>()
  public $refreshFlexiAttendaceList = new Subject<boolean>()
  public $flexiAttendanceSubmit = new Subject<attendanceTypes.FlexiAttendanceOutput>()
  public $deleteChildFlexiComponent = new Subject<number>()

  constructor() { }

  triggerAttendanceList() {
    this.$eattendanceListRefresh.next(true)
  }
  triggerDeleteChildFlexi(index: number) {
    this.$deleteChildFlexiComponent.next(index)
  }
  triggerflexiSubmission(data: attendanceTypes.FlexiAttendanceOutput) {
    this.$flexiAttendanceSubmit.next(data)
  }

  triggerAttendanceApprovalList() {
    this.$eattendanceApprovalListRefresh.next(true)
  }
  triggerEmployeeChange(data: attendanceTypes.FlexiUserApprovalData) {
    this.$flexiEmployeeChangeDetaction.next(data)
  }
  triggerEmployeeFlexiListChange(data) {
    this.$flexiEmployeeFlexiListChangeDetaction.next(data)
  }
  triggerCheckAll(clicked) {
    this.$flexiCheckAllApprove.next(clicked)
  }
  triggerRefreshApprovalList() {
    this.$trigerApprovalListRefresh.next(true)
  }
  triggerRefreshFlexiList() {
    this.$refreshFlexiAttendaceList.next(true)
  }
  async triggerAfterApproveReject() {
    this.refreshAfterAction.next(true)
  }
  triggerIndividualCheckbox(clicked) {
    this.$flexiIndividualCheckbox.next(clicked)
  }
  triggerClearAllEmplComp() {
    this.$clearAllEmplComp.next(true)
  }
  triggerSelectionEmpComp(bool: boolean) {
    this.$flexiEmployeeCompanySelect.next(bool)
  }
  triggerRefreshPage() {
    this.$refreshPage.next(true)
  }

}
