import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgOption, NgSelectComponent } from '@ng-select/ng-select';
import { concat, Observable, of, pipe, Subject, Subscription, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, find, pluck, tap } from 'rxjs/operators';
import { isEmpty } from 'class-validator';
import { Platform } from '@ionic/angular';
import * as monthyearservice from '../ngselect-attendance-month-year-select/attendance-list-filter-service';
import * as moment from 'moment';
// import { DeviceDetails } from '@coreglobalsr/device-details.service';

export interface MonthYear {
  selectedMonthString: string
  selectedMonthNum: number
  selectedYear: number
}
@Component({
  selector: 'app-ngselect-attendance-month-year-select',
  templateUrl: './ngselect-attendance-month-year-select.component.html',
  styleUrls: ['./ngselect-attendance-month-year-select.component.css']
})


export class NgselectAttendanceMonthsYearSelectComponent implements OnInit, OnDestroy, AfterViewInit {

  // public month$: Observable<Months[]>
  public monthyearLoading = false
  public monthSelected: Array<monthyearservice.MonthsList> = []
  public monthSub: Subscription
  public yearSub: Subscription
  public baseStruct: monthyearservice.MonthYear
  public monthIndex = [
    { label: "January", id: 0 },
    { label: "February", id: 1 },
    { label: "March", id: 2 },
    { label: "April", id: 3 },
    { label: "May", id: 4 },
    { label: "June", id: 5 },
    { label: "July", id: 6 },
    { label: "August", id: 7 },
    { label: "September", id: 8 },
    { label: "October", id: 9 },
    { label: "November", id: 10 },
    { label: "December", id: 11 }
  ]
  public momentInstance = moment()
  public monthList: string[] = []
  public yearList: number[] = []
  public currentMonthNum: number = 0
  public currentMonthStr: string
  public currentYear: number = this.momentInstance.year()
  public $monthFilterChangeDetection: BehaviorSubject<monthyearservice.MonthYear>
  public _monthFilterSubscription: Observable<monthyearservice.MonthYear>
  public id = 0
  public index = 0
  monthyearInput$ = new Subject<string>()
  // monthyearList: string[]
  public nums = 0
  // public monthSub = Subscription
  @ViewChild('monthSelects') public monthSelects: NgSelectComponent
  @ViewChild('yearSelects') public yearSelects: NgSelectComponent
  @Input('selectedMonth') public selectedMonth: string
  @Input('selectedyear') public selectedYear: number
  @Input('removeSelected') public removeSelected = new EventEmitter<string>()
  @Output('selectedMonthYearOutput') public selectedMonthYearOutput = new EventEmitter<Array<monthyearservice.MonthsList>>()
  displayMonthsYearList = new EventEmitter<monthyearservice.MonthsList[]>()


  constructor(
    private platform: Platform,
    private filterService: monthyearservice.AttendanceListFilterServices

  ) {
    this.$monthFilterChangeDetection = new BehaviorSubject(<monthyearservice.MonthYear>this.baseStruct)
    this._monthFilterSubscription = this.$monthFilterChangeDetection.asObservable()
  }

  ngOnInit(): void {
    this.loadMonthsYear()
  }


  ngAfterViewInit(): void {
    this.selectedMonth = this.filterService.currentMonthStr
    this.selectedYear = this.filterService.currentYear
    this.trackMonthSelected()
  }

  ngOnDestroy(): void {
    this.monthSub.unsubscribe()
  }

  loadMonthsYear() {
    this.monthList = moment.months()
    const currentYear = moment().year()
    this.yearList = [currentYear - 1, currentYear, currentYear + 1]
  }

  setMonthsYear(monthsyear: monthyearservice.MonthsList[]) {
    this.displayMonthsYearList.emit(monthsyear)
  }

  dataInterceptor(data: monthyearservice.MonthsList) {
    this.monthSelected.pop()
    if (!isEmpty(data)) {
      this.monthSelected.push(data)
    } else {
      this.monthSelected = [{
        month_of_leave: ""
      }]
    }
    return this.monthSelected
  }

  trackMonthSelected() {
    this.monthSub = this.monthSelects.changeEvent.pipe(
      distinctUntilChanged()
    ).subscribe(month => {
      // console.log({ month })
      if (month && month !== undefined) {
        this.filterService.currentMonthNum = Number(this.checked_month_number(month))
        this.filterService.selectedMonth(this.filterService.currentMonthNum)
      } else {
        this.filterService.selectedMonth(-1)
      }
      this.filterService.applyFilter()
    })

    this.yearSub = this.yearSelects.changeEvent.pipe(
      distinctUntilChanged()
    ).subscribe(year => {
      // console.log({ year });
      if (year && year !== undefined) {
        this.filterService.selectedYear(year)
      } else {
        this.filterService.selectedYear(0)
      }
      this.filterService.applyFilter()
    })


  }

  onRemove(event: any) {
    // console.log(event, 'remove');
    this.trackMonthSelected()
  }

  getCurrentMonth() {
    this.filterService.currentMonthStr = this.filterService.monthList[this.filterService.currentMonthNum]
  }

  checked_month_number(month) {
    this.index = this.monthIndex.findIndex((element) => element.label == month)
    this.id = this.monthIndex[this.index].id;
    return this.id
  }

  clearMonth() {
    this.monthSelects.itemsList.clearSelected()
    this.yearSelects.itemsList.clearSelected()
    this.monthSelects.clearModel()
    this.yearSelects.clearModel()
    this.trackMonthSelected()
  }

}