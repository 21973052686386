import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { ExpenseClaimCore } from 'fortunebit-staffhub/src/eclaim/services/eclaim-core';
import { EclaimMetaData, MetaData } from 'fortunebit-staffhub/src/eclaim/services/eclaim-metadata';
import { ClassValidatorFormGroup } from 'ngx-reactive-form-class-validator';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as eclaimTypes from 'fortunebit-staffhub/src/eclaim/services/typings/eclaim-api.typings' // Get everything for expense claim type
import { EclaimListingService } from '../eclaim-listing/services/eclaim-listing.service';
import { ChangeDetectionService } from '../shared/change-detection.service';
import { Router } from '@angular/router';
import { attachement } from 'fortunebit-staffhub/src/typings/common-api.typings';
import { FileSelectorService } from 'fortunebit-staffhub/src/services/file-selector.service';


@Component({
  selector: 'app-create-claim.page',
  templateUrl: './create-claim.page.html',
  styleUrls: ['./create-claim.page.scss']
})
export class CreateClaimPage implements OnInit {

  @Output('closeModal') closeModal = new EventEmitter<boolean>()

  public eclaimMetaData: MetaData
  public form: ClassValidatorFormGroup
  public disableAmount: boolean = false
  public mileageForm: boolean = false
  public readOnlyDistance: boolean = true
  public selected_files: attachement | null = null
  public gotAttachment: boolean = false
  public fileNotSupported: string = ''
  public locationList: Array<any>
  public ExpenseList: any = []
  public valueChanges = {
    'expense_type': undefined,
    'mileage_from': undefined,
    'mileage_to': undefined,
    'return_trip': undefined,
    'mileage_distance': undefined
  }

  constructor(
    @Inject(EclaimMetaData) private eclaimMeta: EclaimMetaData,
    @Inject(ChangeDetectorRef) private changeDetact: ChangeDetectorRef,
    @Inject(ExpenseClaimCore) public expenseClaimCore: ExpenseClaimCore,
    @Inject(FileSelectorService) private fileSelector: FileSelectorService,
    @Inject(ChangeDetectionService) private changeDetection: ChangeDetectionService
  ) {
    this.eclaimMetaData = this.eclaimMeta.initMetaData()
    this.form = this.eclaimMetaData.ExpenseClaimDetail.form
    this.locationList = this.expenseClaimCore.getFromToList()
    this.formListenerExpenseType()
  }


  formListenerExpenseType() {
    // this.form.contains('expense_type')
    this.valueChanges.expense_type = this.form.controls.expense_type.valueChanges.subscribe(extp => {
      this.eclaimMeta.activateMilleageClaim(this.form)
      //disable amount on startup

      if (this.form.contains('mileage_to') || this.form.contains('mileage_from')) {
        this.disableAmount = true
        this.mileageForm = true
        // activate mileage listener
        this.valueChanges.mileage_from = this.form.controls.mileage_from.valueChanges.subscribe(milfrm => {
          if (this.form.controls['mileage_to'].value == 'Others' || this.form.controls['mileage_from'].value == 'Others') {
            this.readOnlyDistance = false
            // Enable distance calculator
            // this.expenseClaimCore.determineOtherMileageDistance(this.form)
            this.form.updateValueAndValidity()
            this.changeDetact.detectChanges()
          } else {
            this.readOnlyDistance = true
            this.expenseClaimCore.determineMileageDistance(this.form)
            this.form.updateValueAndValidity()
            this.changeDetact.detectChanges()
          }
        })

        this.valueChanges.mileage_to = this.form.controls.mileage_to.valueChanges.subscribe(milto => {
          if (this.form.controls['mileage_to'].value == 'Others' || this.form.controls['mileage_from'].value == 'Others') {
            this.readOnlyDistance = false
            // Enable distance calculator
            // this.expenseClaimCore.determineOtherMileageDistance(this.form)
            this.form.updateValueAndValidity()
            this.changeDetact.detectChanges()
          } else {
            this.readOnlyDistance = true
            this.expenseClaimCore.determineMileageDistance(this.form)
            this.form.updateValueAndValidity()
            this.changeDetact.detectChanges()
          }
          setTimeout(() => {
            if (this.form.controls.amount.value == 0) {
              this.form.controls['amount'].setErrors({ 'incorrect': true }, { emitEvent: false })
              this.form.updateValueAndValidity()
              this.changeDetact.detectChanges()
            } else {
              this.form.controls['amount'].clearValidators()
              this.form.controls['amount'].updateValueAndValidity()
              this.changeDetact.detectChanges()
            }
          }, 500);
        })

        this.valueChanges.return_trip = this.form.controls.mileage_return.valueChanges.subscribe(rtip => {
          // console.log({ rtip });
          if (rtip == false) {
            this.form.controls['mileage_distance'].setValue(this.form.controls['mileage_distance'].value / 2, { emitEvent: false })
          }
          if (this.form.controls['mileage_to'].value == 'Others' || this.form.controls['mileage_from'].value == 'Others') {
            this.readOnlyDistance = false
            // Enable distance calculator
            // this.expenseClaimCore.determineOtherMileageDistance(this.form)
            this.form.updateValueAndValidity()
            this.changeDetact.detectChanges()
          } else {
            this.readOnlyDistance = true
            this.expenseClaimCore.determineMileageDistance(this.form)
            this.form.updateValueAndValidity()
            this.changeDetact.detectChanges()
          }
          setTimeout(() => {
            if (this.form.controls['amount'].value == 0) {
              this.form.controls['amount'].setErrors({ 'incorrect': true }, { emitEvent: false })
              this.form.updateValueAndValidity()
              this.changeDetact.detectChanges()
            } else {
              this.form.controls['amount'].clearValidators()
              this.form.controls['amount'].updateValueAndValidity()
              this.changeDetact.detectChanges()
            }
          }, 500);
        })

        this.valueChanges.mileage_distance = this.form.controls.mileage_distance.valueChanges.pipe(
          debounceTime(500),
          distinctUntilChanged()
        ).subscribe(distance => {
          // console.log({ distance });
          setTimeout(() => {
            if (this.form.controls['amount'].value == 0) {
              this.form.controls['amount'].setErrors({ 'incorrect': true }, { emitEvent: false })
              this.form.updateValueAndValidity()
              this.changeDetact.detectChanges()
            }
          }, 500);
          // Only activate on others
          if (distance != null || distance != "" || distance != 0) {
            if (this.form.controls['mileage_to'].value == 'Others' || this.form.controls['mileage_from'].value == 'Others') {
              this.readOnlyDistance = false
              // Enable distance calculator
              // this.expenseClaimCore.determineOtherMileageDistance(this.form)
              this.form.controls['amount'].clearValidators()
              this.form.controls['amount'].updateValueAndValidity()
              this.changeDetact.detectChanges()
            } else {
              this.readOnlyDistance = true
            }
          }
          if (distance == null || distance == 0) {
            this.form.controls['amount'].setErrors({ 'incorrect': true }, { emitEvent: false })
            this.form.updateValueAndValidity()
            this.changeDetact.detectChanges()
          }
        })

      } else {
        this.mileageForm = false
        this.disableAmount = false
        if (this.valueChanges.mileage_from != undefined) {
          // disable listener
          this.valueChanges.mileage_from.unsubscribe()
        }
        if (this.valueChanges.mileage_to != undefined) {
          // disable listener
          this.valueChanges.mileage_to.unsubscribe()
        }
        if (this.valueChanges.return_trip != undefined) {
          // disable listener
          this.valueChanges.return_trip.unsubscribe()
        }
        if (this.valueChanges.mileage_distance != undefined) {
          // disable listener
          this.valueChanges.mileage_distance.unsubscribe()
        }
      }
    })
  }

  ngOnInit(): void { }

  submitForm() {
    if (this.gotAttachment) {
      this.expenseClaimCore.submitClaim(this.form.getRawValue()).then((data: any) => {
        this.expenseClaimCore.upload_attachement(this.selected_files, data['message']['message']).then((data_2 => {
          this.changeDetection.triggerClaimList()
          this.closeModal.emit(true)
        }))
      })
    } else {
      this.expenseClaimCore.submitClaim(this.form.getRawValue()).then(() => {
        this.changeDetection.triggerClaimList()
        this.closeModal.emit(true)
      })
    }
  }

  selectedFile(event) {
    // console.log(event, 'event');
    this.fileSelector.fetchSelectedFile(event).then((data: attachement) => {
      // console.log(data, 'file data');
      this.form.controls['file_attachment'].markAsDirty()
      this.selected_files = data
      this.gotAttachment = true
      this.fileNotSupported = ''
      this.changeDetact.detectChanges()
    }).catch(err => {
      this.fileNotSupported = ''
      this.fileNotSupported = err
      console.log(err, 'file upload error');
      this.changeDetact.detectChanges()
    })
  }

  disableSave() {
    if (!this.form.valid || !this.form.dirty) {
      return true
    }
    return false
  }

  removeContent() {
    // console.log('removeContent');
    this.selected_files = null
    this.gotAttachment = false
  }

}
