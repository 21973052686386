import { Component, OnInit, Inject, ElementRef, AfterViewInit, ViewContainerRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { SideMenuEmitService } from 'fortunebit-staffhub/src/core/global-emitters/glob-side-menu.emit.services';
import { GlobalVariables } from 'fortunebit-staffhub/src/libs';
import { AuthLogoutService } from 'fortunebit-staffhub/src/auth/auth_logout.service';
import { Router } from '@angular/router';
import { SidemenuService } from '@desktop-common-comp/services/sidemenu.service';

interface childItems {
  name: string
  value: string
  url?: string
}

interface MenuItems {
  name: string
  value: string
  url?: string
  img?: string
  childModule: boolean
  childItems?: Array<childItems>
}

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
})

export class SidemenuComponent implements OnInit, AfterViewInit {

  public moduleList: boolean = false
  public moduleApproval: boolean = false
  public activeMenu: string = '';
  public activeChildMenu: string = '';
  public state: boolean = false
  public menuItems: MenuItems[] = [
    {
      name: 'Dashboard',
      value: 'dashboard',
      url: '',
      img: './assets/img/icon/dashboard.png',
      childModule: false
    },
    {
      name: 'Profile',
      value: 'profile',
      url: '',
      img: './assets/img/icon/user_setting.png',
      childModule: false
    },
    {
      name: 'Listing',
      value: 'listing',
      img: './assets/img/icon/listing.png',
      childModule: false,
      childItems: [
        { name: 'E-Claim', value: 'eclaim', url: '' },
        { name: 'E-Leave', value: 'eleave', url: '' },
        { name: 'HR-Employee-Leave', value: 'hremployeeleave', url: '' },
      ]
    },
    {
      name: 'Approval',
      value: 'approval',
      img: './assets/img/icon/approval.png',
      childModule: false,
      childItems: [
        { name: 'E-Claim', value: 'eclaim', url: '' },
        { name: 'E-Leave', value: 'eleave', url: '' },
      ]
    },
    {
      name: 'Logout',
      value: 'logout',
      img: './assets/img/icon/logout.png',
      childModule: false
    }
  ]

  constructor(
    private ref: ElementRef,
    @Inject(Router) public router: Router,
    @Inject(GlobalVariables) public global: GlobalVariables,
    @Inject(AuthLogoutService) private authLogout: AuthLogoutService,
    @Inject(ChangeDetectorRef) private changeDetact: ChangeDetectorRef,
    @Inject('SIDEMENUGLOBAL') private sideMenuService: SideMenuEmitService
  ) {
    this.sideMenuService.selectedItem.subscribe((menu) => {
      // console.log({ menu });
      if (menu) {
        this.activeMenu = menu
        if (this.activeMenu == 'listing') {
          this.moduleList = true
          this.moduleApproval = false
        } else if (this.activeMenu == 'approval') {
          this.moduleApproval = true
          this.moduleList = false
        } else {
          this.moduleList = false
          this.moduleApproval = false
        }
      }
    })
    this.sideMenuService.selectedChildItem.subscribe((childMenu) => {
      this.activeChildMenu = childMenu
    })
  }


  ngOnInit(): void { }

  ngAfterViewInit(): void {
    // Register template ref
    this.sideMenuService.registerComponent(this.ref).subscribe((stateData) => {
      // Handle state inside components
      this.setState()
    })
    // console.log(this.router, 'router');
  }

  getState() {
    return this.state
  }

  setState() {
    if (this.state == true) {
      this.state = false
    } else {
      this.state = true
    }
  }

  openListing() {
    this.moduleApproval = false
    this.moduleList = !this.moduleList
    this.sideMenuService.selectedItem.next('listing')
  }

  openApproval() {
    this.moduleList = false
    this.moduleApproval = !this.moduleApproval
    this.sideMenuService.selectedItem.next('approval')
  }


  logout() {
    this.sideMenuService.emitEvent(false)
    this.authLogout.logOut()
  }

  onClick(event) {
    console.log({ event });
  }


}
