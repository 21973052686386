import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgOption, NgSelectComponent } from '@ng-select/ng-select';
import { concat, Observable, of, pipe, Subject, Subscription, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, find, pluck, tap } from 'rxjs/operators';
import { isEmpty } from 'class-validator';
import { Platform } from '@ionic/angular';
// import * as monthyearservice from './month-year-list-filter-service';
import * as moment from 'moment';
import { MonthYearListFilterService, MonthsYear } from '../ngselect-attendance-month-year-select/month-year-list-filter-service';
import { DeviceDetails } from '@coreglobalsr/device-details.service';

export interface MonthYear {
  selectedMonthString: string
  selectedMonthNum: number
  selectedYear: number
}

@Component({
  selector: 'app-ngselect-month-year-select',
  templateUrl: './ngselect-month-year-select.component.html',
  styleUrls: ['./ngselect-month-year-select.component.css']
})


export class NgselectMonthYearSelectComponent implements OnInit, OnDestroy, AfterViewInit {

  // public month$: Observable<Months[]>
  public monthLoading = false
  public yearLoading = false
  public monthyearSelected: Array<MonthsYear> = []
  public monthyearSub: Subscription
  public baseStruct: MonthYear
  public monthIndex = [
    { label: "January", id: 0 },
    { label: "February", id: 1 },
    { label: "March", id: 2 },
    { label: "April", id: 3 },
    { label: "May", id: 4 },
    { label: "June", id: 5 },
    { label: "July", id: 6 },
    { label: "August", id: 7 },
    { label: "September", id: 8 },
    { label: "October", id: 9 },
    { label: "November", id: 10 },
    { label: "December", id: 11 }
  ]
  public momentInstance = moment()
  // public monthList = moment.months()
  public currentMonthNum: number = 0
  public currentMonthStr: string
  public currentYear: number = this.momentInstance.year()
  public $monthFilterChangeDetection: BehaviorSubject<MonthYear>
  public _monthFilterSubscription: Observable<MonthYear>
  public id = 0
  public index = 0
  monthInput$ = new Subject<string>()
  yearInput$ = new Subject<string>()
  monthsList: string[]
  yearList: string[]
  public nums = 0

  selectedCounter: number = 0
  @ViewChild('monthSelect') public monthSelect: NgSelectComponent
  @ViewChild('yearSelect') public yearSelect: NgSelectComponent
  @Input('selectedMonth') public selectedMonth: string
  @Input('selectedYear') public selectedYear: string
  @Input('removeSelected') public removeSelected = new EventEmitter<string>()
  @Input('enableMonth') public enableMonth: boolean = false
  @Input('enableYear') public enableYear: boolean = false
  @Output('selectedMonthYearOutput') public selectedMonthYearOutput = new EventEmitter<Array<MonthsYear>>()
  @Input('selectedMonthListner') public selectedMonthListner = new EventEmitter<boolean>(false)
  @Input('selectedYearListner') public selectedYearListner = new EventEmitter<boolean>(false)
  @Input('selectedYearOnEleave') public selectedYearOnEleave = new BehaviorSubject<string>('')
  displayMonthsYearList = new EventEmitter<MonthsYear[]>()


  constructor(
    private platform: Platform,
    public deviceDetails: DeviceDetails,
    private filterService: MonthYearListFilterService
  ) {
    this.$monthFilterChangeDetection = new BehaviorSubject(<MonthYear>this.baseStruct)
    this._monthFilterSubscription = this.$monthFilterChangeDetection.asObservable()
    this.filterService.refreshMonthYear()
  }

  ngOnInit(): void {
    // this.getMonthsYear()
    this.loadMonths()
    this.loadyears()
  }


  ngAfterViewInit(): void {

    this.trackMonthSelected()
    this.trackYearSelected()
    // this.monthChange()
  }

  getCounter() {
    return this.selectedCounter
  }

  clearAll() {
    this.monthSelect.itemsList.clearSelected()
    this.monthSelect.clearModel()
    this.selectedCounter = 0
  }

  ngOnDestroy(): void {
    console.log("HWAAAAAA")
    this.filterService.refreshMonthYear()
    // this.monthSub.unsubscribe()
  }

  loadMonths() {
    this.monthsList = moment.months()
    // console.log(moment.months())
  }

  loadyears() {
    const currentYear = this.momentInstance.year();
    // Generate an array of the current year and the previous years
    this.yearList = [(currentYear - 1).toString(), (currentYear).toString()];

  }

  setMonthsYear(monthsyear: MonthsYear[]) {
    this.displayMonthsYearList.emit(monthsyear)
  }

  // dataInterceptor(data: MonthsYear) {
  //   this.monthyearSelected.pop()
  //   if (!isEmpty(data)) {
  //     this.monthyearSelected.push(data)
  //   } else {
  //     this.monthyearSelected = [{
  //       month_of_leave: ""
  //     }]
  //   }
  //   return this.monthyearSelected
  // }

  trackMonthSelected() {
    // this.filterService.currentMonthNum = this.filterService.momentInstance.add(1, 'month').month()
    if (this.enableMonth) {
      this.monthSelect.changeEvent.subscribe(month => {
        console.log(month, 'from hereeeee')
        this.filterService.currentMonthNum = Number(this.checked_month_number(month))
        this.getCurrentMonth()
        this.selectedMonthListner.emit(true)
      })
    }
  }

  trackYearSelected() {
    // this.filterService.currentMonthNum = this.filterService.momentInstance.add(1, 'month').month()
    if (this.enableYear) {
      this.yearSelect.changeEvent.subscribe(year => {
        console.log(year, 'from hereeeee')
        this.filterService.currentYear = Number(year)
        this.selectedYearListner.emit(true)
        this.selectedYearOnEleave.next(year)
      })
    }
  }

  onRemoveMonth(event: any) {
    console.log(event, 'remove');
    this.trackMonthSelected()

  }

  onRemoveYear(event: any) {
    console.log(event, 'remove');
    this.trackYearSelected()

  }

  getCurrentMonth() {
    this.filterService.currentMonthStr = this.filterService.monthList[this.filterService.currentMonthNum]
    // console.log(this.filterService.currentMonthStr, 'currentMonthStr');
  }

  checked_month_number(month) {
    if (month == undefined) {
      return undefined
    }
    this.index = this.monthIndex.findIndex((element) => element.label == month)
    this.id = this.monthIndex[this.index].id;
    return this.id
  }


}