import { NgOption } from "@ng-select/ng-select"

export class UserAndAprooverLeaveSummaryDatas {
  employee_name: string = ''
  approved_leaves: number = 0
  pending_leaves: number = 0
  rejected_leaves: number = 0
}

export class GetCommonInput {
  employee?: string
  company?: string
  approver_id?: string
}

export interface attachement {
  type: string,
  data: string,
  data_type: string,
  name: string
}

export interface Status {
  status_selected: string,
  metadata?: NgOption
}

export class GetCheckedInput {
  checked?: string
  status?: string
  approver?: string
  remark?: string
}

export interface GetEleaveDetailInput {
  name: string
}

export class EleaveLisingFilterMeta {
  employee: string = ''
  from_date: string = ''
  to_date: string = ''
  leave_type: string = 'All'
  status: 'All' | 'Pending' | 'Approved' | 'Rejected' | 'Cancelled' = 'Pending'
  order_by: string
  // company: string = '*'
  // employee: string = ''
}

export interface Months {
  selectedMonth: string,
  metadata?: NgOption
}

export class GetNumberOfLeaveInput {
  employee: string
  leave_type: string
  from_date: string
  to_date: string
  include_weekend: number
  weekend_dates: string
  half_day: number
  half_day_dates: string
}

export class GetNumberOfWeekendAndHalfdayInput {
  from_date: string
  to_date: string
  employee?: string
}

export class GetLeaveBalanceDatas {
  leave_type: string
  total_allocated_leave: number
  used_leaves: number
}

export interface GetLeaveApplicationInput {
  filters: string,
  status: number
}

export interface IndividualLeaveDetails {
  company: string
  compassionate_reason: string
  creation: string
  description: string
  docstatus: number
  doctype: string
  employee: string
  employee_name: string
  follow_via_email: boolean
  from_date: string
  half_day: number
  half_day_date_jq_data: string
  idx: number
  include_weekend: boolean
  is_hospitalized: boolean
  is_imported: boolean
  leave_approver: string
  leave_approver_id: string
  leave_approver_name: string
  leave_balance: number
  leave_type: string
  letter_head: string
  modified: string
  modified_by: string
  name: string
  naming_series: string
  owner: string
  posting_date: string
  select_weekend: string
  status: string
  to_date: string
  total_leave_days: number
  workflow_state: string
}

export class LeaveApplicationDatas {
  amended_from: string
  action: string
  attendance_device_id: null
  bank_ac_no: null
  bank_name: null
  bio: null
  blood_group: ""
  branch: null
  color: string
  company: string
  compassionate_reason: string
  creation: string
  cancellation_remark: null
  cell_number: null
  company_email: null
  contract_end_date: null
  create_user_permission: 0
  current_accommodation_type: ""
  current_address: null
  data_101: null
  date_of_birth: "1995-12-23"
  date_of_issue: null
  date_of_joining: "2020-03-05"
  date_of_retirement: "2055-12-23"
  default_shift: "Flexi-42"
  designation: null
  department: string
  description: string
  docstatus: number
  employee: string
  employee_name: string
  emergency_phone_number: null
  employee_number: null
  employment_type: null
  encashment_date: null
  expense_claim_cost_center: null
  expenses_manager_approver: 0
  expire_carry_forwarded_leaves_after_days: null
  follow_via_email: string
  from_date: string
  from_time: string
  file_attachment: string
  family_background: null
  feedback: null
  final_confirmation_date: "2020-06-05"
  first_name: "Ramya"
  gender: "Female"
  grade: null
  half_day: number
  half_day_date: string
  half_day_date_jq_data: string
  health_details: null
  health_insurance_no: null
  health_insurance_provider: null
  held_on: null
  holiday_list: "Initial Flexi Holiday List"
  hr_employee_id: "TEST"
  hr_remark: string
  idx: number
  image: null
  imported_date: string
  include_weekend: number
  is_hospitalized: number
  is_imported: number
  job_applicant: null
  last_name: "Annamalai"
  leave_attachment: null
  leave_encashed: ""
  leave_policy: "HR-LPOL-2023-NExec-00002"
  lft: 1293
  leave_approver: string
  leave_approver_id: string
  leave_approver_name: string
  leave_balance: number
  leave_type: string
  letter_head: string
  marital_status: ""
  maximum_carry_forwarded_leaves: null
  middle_name: null
  mortgage_team: null
  modified: string
  modified_by: string
  name: string
  naming_series: string
  new_workplace: null
  notice_number_of_days: 0
  old_parent: "EMP/00565"
  ot_enabled: 1
  owner: string
  parent: string
  parentfield: string
  parenttype: string
  posting_date: string
  passport_number: null
  permanent_accommodation_type: ""
  permanent_address: null
  person_to_be_contacted: null
  personal_email: null
  place_of_issue: null
  prefered_contact_email: ""
  prefered_email: "ramya@tektician.com"
  process_attendance: 1
  property_sales_campaign_agency_type: ""
  property_sales_team: null
  requires_hr_approval: number
  reason_for_leaving: null
  reason_for_resignation: ""
  relation: null
  relieving_date: null
  reports_to: "EMP/00565"
  require_director_approval: 0
  resignation_letter_date: null
  rgt: 1296
  salary_mode: ""
  salutation: null
  scheduled_confirmation_date: null
  salary_slip: string
  select_weekend: string
  status: string
  transport_allowance: 0
  to_date: string
  to_time: string
  total_leave_days: number
  unsubscribed: 0
  user_id: "ramya@tektician.com"
  valid_upto: null
  view_settings: null
  workflow_state: string
}


export class EleaveSummaryInfo {
  all_leaves: number
  approved_leaves: number
  employee: string
  designation: string
  employee_name: string
  gender: string
  pending_leaves: number
  rejected_leaves: number
  company: string
  department: string
  reports_to: string
  selected: boolean
  openClose: boolean
  hr_employee_id: string
  reports_to_name: string
  creation: string
  date_of_joining: string
  status: string
  grade: string
}

export class EleaveSummaryInfoDefault implements EleaveSummaryInfo {
  all_leaves = 0
  approved_leaves = 0
  employee = ''
  designation = ''
  employee_name = ''
  gender = ''
  pending_leaves = 0
  rejected_leaves = 0
  company = ''
  department = ''
  reports_to = ''
  selected = false
  openClose = false
  hr_employee_id = ''
  reports_to_name = ''
  creation = ''
  date_of_joining = ''
  status = ''
  grade = ''
}

export class EleaveEmployeeDetailsHR {
  employee: string
  designation: string
  employee_name: string
  gender: string
  company: string
  department: string
  status: string
  reports_to: string
  yearSelected?: number
  selected: boolean
  openClose: boolean
}

export class EleaveEmployeeDetailsHRDefault implements EleaveEmployeeDetailsHR {
  employee = ''
  designation = ''
  employee_name = ''
  gender = ''
  company = ''
  department = ''
  status = ''
  reports_to = ''
  yearSelected? = 0
  selected = false
  openClose = false
}

export class EleaveParentBaseArgs {
  approver_id = ""
  company: string = "*"
  employee: string = ""
  start_page?: number = 0
  end_page?: number = 20
  status?: string = ''
}

export class EleaveParentBaseArgsHR {
  company: string = "*"
  employee: string = ""
  creation_year: number = 0
  start_page?: number = 0
  end_page?: number = 20
  status?: string = ''
}

export class EleaveChildApproveBaseArgsHR {
  company: string = "*"
  employee: string = ""
  year?: string = ""
  status?: string = ""
  leave_type?: string = ""
}

export class EmployeeLeaveDetailsArgsHR {
  employee: string = ""
  date: string = ""
  is_hospitalized: boolean = false
}

export interface LeaveDetails {
  carry_forward: number
  carry_forward_taken: number
  expired_leaves: number
  entitlement: string | number
  expired_carry_forward: number
  leaves_allocated: number
  leaves_taken: number
  pending_leaves: number
  prorated_leaves: number
  remaining_leaves: number
  total_leaves: number
  unused_carry_forward: number
  normal_allocation: AllocationTimeframe
  carry_forward_allocation: AllocationTimeframe
  allocation_details: Array<LeaveAllocationDetails>
}

export interface AllocationTimeframe {
  from_date: string
  to_date: string
}

export interface LeaveDetails {
  carry_forward: number
  carry_forward_taken: number
  expired_leaves: number
  leaves_allocated: number
  leaves_taken: number
  pending_leaves: number
  prorated_leaves: number
  remaining_leaves: number
  total_leaves: number
  unused_carry_forward: number
  allocation_details: Array<LeaveAllocationDetails>
  normal_allocation: AllocationTimeframe
  carry_forward_allocation: AllocationTimeframe
}

export interface LeaveAllocationDetails {
  leave_type: string
  from_date: string
  to_date: string
  is_carry_forward: number
}


// export interface LeaveAllocation {
//   [key: string]: LeaveDetails
// }

export interface LeaveAllocArrayDetails {
  leave_type: string,
  allocation_details: LeaveDetails
}

export interface LeaveBalance {
  leave_allocation: LeaveDetails
  leave_approver: string
}

interface LeaveTypeKey {
  'Annual Leave': string;
  'Annual Leave (Replacement)': string;
  'Calamity Leave': string;
  'Compassionate Leave': string;
  'Hospitalization Leave': string;
  'Marriage Leave': string;
  'Maternity Leave': string;
  'Medical Leave': string;
  'Paternity Leave': string;
  'Replacement Leave': string;
}

export type LeaveTypes = keyof LeaveTypeKey;

export type LeaveAllocation = {
  [K in LeaveTypes]: LeaveDetails;
}

export class LeaveAllocationData {
  leave_allocation: LeaveDetails
  leave_approver: string
}

export class EleaveHRFilterMeta {
  company: string = '*'
  employee: string = ''
  creation: number = 0
  department: string = ''
  // status: 'All' | 'Pending' | 'Approved' | 'Rejected' = 'Pending'
}

export interface Company {
  value: string,
  description: string,
  metadata?: NgOption
}

export interface Employee {
  value: string,
  description: string,
  metadata?: NgOption
}

export interface Year {
  value: number,
  metadata?: NgOption
}

export class ApproveLeaveInput {
  name: string
  status: string
  remarks: string
  approver: string
}

// export class EleaveApproveListBaseArgsHR {
//   company: string = "*"
//   employee: string = ""
//   leave_type: string = ""
//   status?: string = ""
// }

export class EleaveLeaveBalanceOnArgs {
  employee: string = ''
  date: string = ''
  to_date: string = ''
  leave_type: string = ''
}
export interface ModeMeta {
  _id: string
  mode: 'create' | 'edit'
}
