<div class="header-area bg-base">
  <img class="ba-nose-img" src="./assets/img/icon/1.png" alt="img">
  <div class="container-customise text-center">
    <div class="header-top">
      <div class="row">
        <div class="col-6">
          <p>8:33</p>
        </div>
        <div class="col-6 text-end">
          <ul>
            <li><img src="./assets/img/icon/37.png" alt="img"></li>
            <li><img src="./assets/img/icon/38.png" alt="img"></li>
            <li><img src="./assets/img/icon/39.png" alt="img"></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">

      <div class="col-sm-6 col-6 text-left" style="display: flex;align-items: center;">
        <div class="menu-bar" (click)="activateMenu()" style="width: fit-content;">
          <svg viewBox="0 0 100 80" width="25" height="25">
            <rect class="bar" width="100" height="20" rx="8"></rect>
            <rect class="bar" y="30" width="60" height="20" rx="8"></rect>
            <rect class="bar" y="60" width="100" height="20" rx="8"></rect>
          </svg>
          <!-- <img style="display:inline-block; margin: 17px 30px 0px 0px;width: 25px;vertical-align: top;"
            src="./assets/img/icon/0.png" alt="logo"> -->
        </div>
        <!-- <div class="col-sm-4 col-6 text-center align-self-center text-center">
          <a style="display:inline-block; margin:auto;" href="#" class="logo title">STAFF HUB
            <span class="subtext">
              Digitally Connected</span>
          </a>
  
        </div> -->
        <div class="">
          <a style="" href="#" class="logo title main">STAFF
            HUB
            <span class="subtext">Digitally Connected</span>
          </a>

        </div>

      </div>
      <div class="col-sm-6 col-6 align-self-center text-end">
        <ul class="header-right">
          <!-- <li (click)="navigateNotification()">
            <a class="modaller" data-modaltarget="#modal_notification">
              <i class="fa fa-bell animated infinite swing"></i>
              <span class="badge">10</span>
            </a>
          </li> -->
          <li>
            <a class="header-user" href="/profile">
              <img src="{{global.user_profile.profile_image}}" alt="user" width="100px" height="100px"
                style="border-radius: 50%; background-image: linear-gradient(0deg, #02a1fb 0%, #7a00ff 100%);">
              <!-- <img src="./assets/img/user.png" alt="img"> -->
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>