import { Injectable } from '@angular/core'
import { HttpServiceService } from "fortunebit-staffhub/src/core/http-service.service"
import { Observable, Subscription, Observer } from 'rxjs'
// import { HODParentSummaryInput, TotalMonthInput, UserAttendanceSummaryInput } from 'fortunebit-staffhub/src/eattendance/services/typings/eattendance-api-typings'
import * as attendanceTypes from 'projects/fortunebit-staffhub/src/eattendance/services/typings/eattendance-api-typings'


@Injectable({
  providedIn: 'root'
})
export class AttendanceAPIService {
  constructor(
    private http: HttpServiceService,
  ) { }

  getAttendanceUserSummary(data) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getAttendanceUserSummary', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getUserListAttendance(data) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getUserListAttendance', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getAttendanceSummaryList(data) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getAttendanceSummaryList', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getAttendanceApprovalSummaryList(data) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getAttendanceApprovalSummaryList', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getAttendanceApprovalParentSummaryList(data: attendanceTypes.HODParentSummaryInput) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getAttendanceApprovalParentSummaryList', data).then(data => {
        // console.log(data, 'serverData');
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getTotalWorkingDays(data) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getAttendanceTotalWorkingDays', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  setUserAttendanceStatus(data) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/setUserUpdateStatus', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  setUserChildAttendanceUpdateRemark(data) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/setUserChildAttendanceUpdateRemark', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  setUserChildAttendanceIndividualRejectRemark(data: attendanceTypes.setAttendanceRejectRemark) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/setAttendanceIndividualRejectRemark', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getPendingHodParentList(data: attendanceTypes.HODParentSummaryInput) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/get_hod_list_attendance_parent', data).then((data: any) => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getPendingHodApprovalChildList(data) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getHodApprovalChildList', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getHodValidMonthSelection() {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getHodValidMonthSelection').then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getMonthlyWeeksTemplate(data: attendanceTypes.TotalMonthInput) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getMonthlyWeeksTemplate', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getUserMonthlyWeekAttendanceStatusSummary(data: attendanceTypes.UserAttendanceSummaryInput) {
    return new Observable((observer: Observer<any>) => {
      this.http.get('/users/getUserAttendanceStatusSummary', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }


  getUserFlexiMonthlyWeekAttendanceList(data: attendanceTypes.UserAttendanceSummaryInput) {
    return new Observable((observer: Observer<any>) => {
      this.http.get('/users/getUserFlexiMonthlyWeekList', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  // Flexi Api's....................................................................................................

  getUserFlexiAttendanceCheckIn(data: any) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getEmployeeCheckin', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  createFlexiAttendance(args: any) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/createFlexiAttendance', args).then((data: any) => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getFlexiCheckInEntries(data: attendanceTypes.FlexiCheckInArgs) {
    return new Observable((observer: Observer<any>) => {
      this.http.get('/users/getFlexiCheckInEntries', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getFlexiAttendanceList(data: attendanceTypes.FlexiAttendanceListArgs) {
    return new Observable((observer: Observer<any>) => {
      this.http.get('/users/getFlexiAttendanceList', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getIndividualFlexiAttendance(name: string) {
    return new Observable((observer: Observer<any>) => {
      this.http.get('/users/getIndividualFlexiAttendance', { name }).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  updateFlexiAttendance(args: any) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/updateFlexiAttendance', args).then((data: any) => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  flexiTransitionWorkflow(args: any) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/flexiTransitionWorkflow', args).then((data: any) => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getFlexiApprovalSummary(data: any) {
    return new Observable((observer: Observer<any>) => {
      this.http.get('/users/flexiApprovalSummary', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getFlexiApprovalItemDetails(data: any) {
    return new Observable((observer: Observer<any>) => {
      this.http.get('/users/flexiApprovalItemsDetails', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  deleteFlexi(args: any) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/deleteFlexi', args).then((data: any) => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  // --------------------------------------------------------------------

  // getFlexiUserApprovals(data: any) {
  //   return new Observable((observer: Observer<any>) => {
  //     this.http.get('/users/flexiUserApprovals', data).then(data => {
  //       console.log(data);

  //       observer.next(data)
  //       observer.complete()
  //     }).catch(error => {
  //       observer.error(error)
  //     })
  //   })
  // }

  getFlexiUserApprovals(params: any) {
    return new Observable((observer: Observer<any>) => {
      this.http.getAfterCancel('/users/flexiUserApprovals', params, 'getFlexiUserApprovals', { code: 200, message: [] }).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      }).then(data => {
        console.log('DATA RESOLVED')
      })
    })
  }

  // --------------------------------------------------------------------

  bulkTransitionWorkflow(data: any) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/flexiBulkTransitionWorkflow', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getNormalAttendanceReport(data: attendanceTypes.GetPDFParams) {
    return new Observable((observer: Observer<any>) => {
      this.http.get('/users/normalAttendanceReport', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getFlexiAttendanceReport(data: attendanceTypes.GetPDFParams) {
    return new Observable((observer: Observer<any>) => {
      this.http.get('/users/getFlexiMonthlyReport', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getUserListAttendanceDoctype(data) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getUserListAttendanceDoctype', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  geolocationCheckinCreation(data: attendanceTypes.CreateCheckInDataType) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/geolocationCheckinCreation', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  geolocationCheckinListing(data: attendanceTypes.CheckInOutParams) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/geolocationCheckinListing', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  showingCheckinCheckoutButton(data: attendanceTypes.CheckInOutParams) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/showingCheckinCheckoutButton', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

}