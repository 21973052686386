import * as moment from 'moment';
import * as eleaveTypes from 'projects/fortunebit-staffhub/src/eleave/services/typings/eleave-api.typings'
import { Inject, Injectable } from '@angular/core';
import { EleaveApprovalCore } from 'fortunebit-staffhub/src/eleave/services/eleave-approval-core';
import { EleaveApproverChangeDetactionService } from 'fortunebit-staffhub/src/eleave/shared/eleave-approver-change-detaction.service';
import { ExpenseEleaveApi, GlobalVariables } from 'fortunebit-staffhub/src/libs';
import { Subject, Subscription } from 'rxjs';
import { ToastService } from '../../../shareable/services/toast.service';
import { EleaveCategoryService } from './eleave-category.service';
import { EleaveFilterService } from './eleave-filter.service';
import { debounceTime, filter, take } from 'rxjs/operators';

export class approvedItem {
  child: eleaveTypes.LeaveApplicationDatas;
  state: string;
  remarks: string;
}

@Injectable({
  providedIn: 'root'
})
export class EleaveApproveService {

  public argument: eleaveTypes.EleaveParentBaseArgsHR = new eleaveTypes.EleaveParentBaseArgsHR()
  public argumentApprove: eleaveTypes.EleaveChildApproveBaseArgsHR = new eleaveTypes.EleaveChildApproveBaseArgsHR()
  public leaveDetaisArgument: eleaveTypes.EmployeeLeaveDetailsArgsHR = new eleaveTypes.EmployeeLeaveDetailsArgsHR()
  public $objvs_ParentSideEleave: Subject<Array<any>> = new Subject()
  public $objvs_LeaveDetails_HR: Subject<eleaveTypes.LeaveAllocationData> = new Subject()
  public employee: string = ''
  public employeeSelection: string = ''
  public year = ''
  public company: string = '*'
  public start_page?: number = 0
  public selectedDate: string = ''
  public creation_year: number = new Date().getFullYear()
  public end_page?: number = 20
  public filter_leave_type: string = 'All'
  public filter_status: string = 'All'
  public filterCount: number = 0
  public childApprovalListData: Array<any> = []
  public subOnInfiniteScroll: Subscription
  public subOnLeaveDetailsChange: Subscription
  public subOnLeaveTypeChange: Subscription
  public leaveDetailsData: Subscription
  public subscriptions: Subscription[] = []
  public $trigerStatus = new Subject<boolean>()
  // public subOnFilterChange: Subscription

  constructor(
    @Inject(EleaveApproverChangeDetactionService) private readonly changeDetaction: EleaveApproverChangeDetactionService,
    @Inject(EleaveCategoryService) private readonly eleaveCategoryService: EleaveCategoryService,
    @Inject(EleaveFilterService) public readonly eleaveFilterService: EleaveFilterService,
    @Inject(EleaveApprovalCore) private readonly eleaveApprovalCore: EleaveApprovalCore,
    @Inject(GlobalVariables) public GlobalVariables: GlobalVariables,
    @Inject(ToastService) public ToastService: ToastService
  ) {
    this.setArgApproval()
    this.getHrEmployeeList()
    this.getCurrentDate()
    this.subOnInfiniteScroll = this.changeDetaction.$eleaveParentInfiniteScroll.subscribe(async (scroll) => {
      console.log({ scroll });
      if (scroll) {
        this.incrementStartPage()
        this.setEmployeeSelection('')
        this.setArgApproval()
        this.getHrEmployeeList()
      }
      this.subscriptions.push(this.subOnInfiniteScroll)
    })

    this.subOnLeaveTypeChange = this.changeDetaction.$eleaveSelectedEmployee.pipe(debounceTime(100), filter(filters => filters !== null)).subscribe(filters => {
      // console.log({ filters });
      const currentYear = moment().year();
      if (filters.yearSelected === 0) {
        filters.yearSelected = currentYear
      }
      const currentDate = moment().format('YYYY-MM-DD');
      const startDate = moment(filters.yearSelected, 'YYYY').startOf('year').format('YYYY-MM-DD');
      const endDate = moment(filters.yearSelected, 'YYYY').endOf('year').format('YYYY-MM-DD');
      const isCurrentDateBetween = moment(currentDate).isBetween(startDate, endDate, null, '[]');
      isCurrentDateBetween ? this.selectedDate = currentDate : this.selectedDate = endDate
      this.setSelectedEmployee(filters.employee)
      this.setLeaveDetaisHRArgs()
      this.getHrEmployeeLeaveDetails()
      this.subscriptions.push(this.subOnLeaveTypeChange)
    })

  }

  unSubscribe() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe()
      })
    }
  }

  setYear(year: string) {
    this.year = year
  }

  getYear() {
    return this.year
  }

  getCurrentDate() {
    this.selectedDate = moment(new Date()).format('YYYY-MM-DD')
  }

  setSelectedDate(date: string) {
    this.selectedDate = date
  }

  triggerStatusList() {
    this.$trigerStatus.next(true)
  }
  setElmployeeId() {
    this.employee = this.GlobalVariables.user_global.employee_id
  }

  getEmployeeId() {
    return this.GlobalVariables.user_global.employee_id
  }

  setCreationYear(year: number) {
    this.creation_year = year
  }

  getCreationYear() {
    return this.creation_year
  }

  setSelectedCompany(company) {
    company === "" ? this.getSelectedCompany : this.company = company
  }

  incrementStartPage() {
    this.start_page += 20
  }

  setStartPage(start: number) {
    this.start_page = start
  }

  getStartPageNum() {
    return this.start_page
  }

  setEndPage(end: number) {
    this.end_page = end
  }

  getEndPageNum() {
    return this.end_page
  }


  getSelectedCompany() {
    return this.company
  }

  setSelectedEmployee(employee) {
    this.employee = employee
  }

  setEmployeeSelection(employee) {
    this.employeeSelection = employee
  }

  getEmployeeSelection() {
    return this.employeeSelection
  }

  getSelectedEmployee() {
    return this.employee
  }

  setFilterStatus(status) {
    this.filter_status = status
    // this.triggerStatusList()
  }

  getFilterStatus() {
    return this.filter_status
  }

  async setFilterLeaveType(leave: string) {
    this.filter_leave_type = leave
  }

  getFilterLeaveType() {
    return this.filter_leave_type
  }

  getTheBaseArg() {
    return this.argument
  }

  getTheApproveArg() {
    return this.argumentApprove
  }

  // 562870519792
  loadYears(back) {
    let years = []
    const year = new Date().getFullYear();
    for (let i = 0; i < back; i++) {
      years.push({ value: (year - back + i + 1) });
    }
    return years
  }

  setArgApproval() {
    this.argument.company = this.getSelectedCompany()
    this.argument.employee = this.getEmployeeSelection()
    this.argument.status = this.getFilterStatus()
    this.argument.creation_year = this.getCreationYear()
    this.argument.start_page = this.getStartPageNum()
    this.argument.end_page = this.getEndPageNum()
  }

  setLeaveDetaisHRArgs() {
    this.leaveDetaisArgument.employee = this.getSelectedEmployee()
    this.leaveDetaisArgument.date = this.selectedDate
    this.leaveDetaisArgument.is_hospitalized = false
  }


  setApproveArgListHr() {
    // console.log(this.getFilterLeaveType())
    this.argumentApprove.company = this.getSelectedCompany()
    this.argumentApprove.employee = this.getEmployeeSelection()
    this.argumentApprove.year = this.getYear()
    this.argumentApprove.status = this.getFilterStatus()
    this.argumentApprove.leave_type = this.getFilterLeaveType()
  }

  getHrEmployeeList() {
    this.eleaveApprovalCore.getHrEmployeeList(this.argument).then((data: Array<eleaveTypes.EleaveEmployeeDetailsHRDefault>) => {
      this.$objvs_ParentSideEleave.next(data);
    })
  }

  getHrEmployeeLeaveDetails() {
    this.eleaveApprovalCore.getHrEmployeeLeaveDetails(this.leaveDetaisArgument).then((data: eleaveTypes.LeaveAllocationData) => {
      // console.log({ data });
      this.$objvs_LeaveDetails_HR.next(data)
    })
  }


  async approveRejectIndividualLeaves(approveData: approvedItem): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.eleaveApprovalCore.approveRejectIndividualLeaves({
        'name': approveData.child.name,
        'status': approveData.state,
        'remarks': approveData.remarks,
        'approver': this.getEmployeeId()
      }).then(data => {
        this.ToastService.successToast(`Succesfully ${approveData.state}`, `Succesfully ${approveData.state}`)
        resolve(true)
      }).catch(error => {
        this.ToastService.errorToast('Unable to approve leave. Please try again later', 'Approval Failed')
        reject(false)
      })
    })
  }



}
