<!-- <div class="modal-overlay" id="modal-overlay" [ngClass]="{'modal-overlay-visible': OpenClose == true}"></div> -->
<div class="modal_box modal-auto main-shadow add-balance-inner-wrap modal_css" [ngClass]="{ 'add-balance-inner-wrap-show': OpenClose == true,
    'modal-auto': size == '',
    'modal-min': size == 'min',
    'modal-half': size == 'half',
    'height-full': size == 'full'}" [@openCloseAnimation]="OpenClose ? 'true' : 'false'">
    <div class="container-modal">
        <div class="modal-dialog" role="document">
            <div class="modal-content" style="margin-top: 25px;">
                <div class="menu-close modal-close" style="cursor: pointer;  padding: 0px;"
                    (click)="closeModalClickedInside()">
                    <i class="la la-times"></i>
                </div>
                <div class="modal-header">
                    <h5 class="modal-title">{{title}}</h5>
                </div>
                <ng-template #modalBody></ng-template>
            </div>
        </div>
    </div>
</div>