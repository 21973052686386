import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef, } from '@angular/core';
import * as moment from 'moment';
import { AttendanceMonthWeekFilterService, FilterTypeMonth, FilterTypeWeek } from './attendance-month-week-filter.service';
import * as attendanceTypes from 'projects/fortunebit-staffhub/src/eattendance/services/typings/eattendance-api-typings'
import { Subject, Subscription } from 'rxjs';
import { Platform } from '@ionic/angular';
import { debounceTime, skip, take } from 'rxjs/operators';

import { DeviceDetails } from '@coreglobalsr/device-details.service';
import { ChangeDetectionService } from '../../../eattendance/shared/change-detection.service';
import { NgSelectComponent } from '@ng-select/ng-select';
export interface weeksStartEnd {
  startDate: string
  endDate: string
}

@Component({
  selector: 'app-attendance-month-week-filter',
  templateUrl: './attendance-month-week-filter.component.html',
  styleUrls: ['./attendance-month-week-filter.component.css']
})
export class AttendanceMonthWeekFilterComponent implements OnInit, OnDestroy, AfterViewInit {

  public momentInstance = moment()
  // moment.months()
  public monthLoading = false
  public weekLoading = false
  monthInput$ = new Subject<string>()
  weekInput$ = new Subject<string>()
  public monthList :  Array<any> = moment.months()
  public currentMonthNum: number = 0
  public selectedMonthStr: string = '00'
  public currentYear: number = this.momentInstance.year()
  public marginTop: string
  public height: number = 0
  public readonly emitter = new EventEmitter<string[]>();
  public initialWeeksAccodDate: string = `Select Week`
  public initialMonthText: string = `Select Month`
  public enableWeekSelection: boolean = false
  public subsMonth: Subscription
  public weeksAccodMonth: Array<weeksStartEnd> = []
  public filterMonthWeekListnerSub: Subscription[] = []
  @ViewChild("monthSelect") public monthSelect: NgSelectComponent
  @ViewChild("weekSelect") public weekSelect: NgSelectComponent
  @Input('selectedMonth') public selectedMonth: Array<any> = []
  @ViewChild("filterModalContainer", { read: ViewContainerRef }) FMD: ViewContainerRef
  @Input('currentWeekStr') public currentWeekStr: string

  constructor(
    private platform: Platform,
    // public deviceDetails: DeviceDetails,
    // private ModalMobile: ModalMobileComponent,
    private changeDetactRef: ChangeDetectorRef,
    private changeDetaction: ChangeDetectionService,
    private monthWeekFilterService: AttendanceMonthWeekFilterService,
  ) {

    this.filterMonthWeekListnerSub.push(this.monthWeekFilterService._attendanceMonthChanges.pipe(
      debounceTime(500)
    ).subscribe(async (monthChoosed) => {
      // console.log(monthChoosed)
      // var newWeekArray = []
      // var newWeek = ""
      // var i = 0
      // monthWeekFilterService.weeksAccodMonth.forEach(week => {
      //   i++
      //   newWeek = "Week "+ i + ": " + week.startDate +" - "+ week.endDate
      //   newWeekArray.push(newWeek)
      // })
      this.weeksAccodMonth = monthWeekFilterService.weeksAccodMonth
      // console.log(this.weeksAccodMonth)
      this.initialWeeksAccodDate = `Select Week`
      this.enableWeekSelection = true
      this.changeDetactRef.detectChanges()
    }))
  }

  async ngOnInit() {
    // this.marginTop = this.monthWeekFilterService.setMarginTop(this.platform.height())
    // this.height = this.monthWeekFilterService.setHeight(this.platform.height())
  }

  ngAfterViewInit(): void {
    this.trackMonthSelected()
    this.trackWeekSelected()
    // this.filterMonthWeekListnerSub.push(this.changeDetaction.$clearFilters.pipe(debounceTime(200)).subscribe(clear => {
    //   // console.log({ clear });
    //   this.enableWeekSelection = false
    //   this.initialMonthText = `Select Month`
    //   this.initialWeeksAccodDate = `Select Week`
    //   setTimeout(() => {
    //     this.monthWeekFilterService.setSelectedMonth(moment(this.monthWeekFilterService.currentMonthStr).format('MM'))
    //     this.monthWeekFilterService.setSelectedYear(this.monthWeekFilterService.currentYear)
    //     this.monthWeekFilterService.getCurrentMonthWeeksDatas()
    //   }, 200);
    //   this.changeDetactRef.detectChanges()
    // }))
  }

  ngOnDestroy(): void {
    if (this.filterMonthWeekListnerSub.length > 0) {
      this.filterMonthWeekListnerSub.forEach(sub => {
        sub.unsubscribe()
      })
    }
  }
  trackMonthSelected() {
    this.filterMonthWeekListnerSub.push(this.monthSelect.changeEvent.subscribe(month => {
      // console.log({ month });
      // this.weekSelect.clearItem
      this.weekSelect.clearModel()
      month === undefined ? month = "" : month
      this.monthWeekFilterService.getFilteredMonth(month)
    }))
  }

  trackWeekSelected() {
    this.filterMonthWeekListnerSub.push(this.weekSelect.changeEvent.subscribe(week => {
      console.log(this.weeksAccodMonth[week] );
      // month === undefined ? month = "" : month
      this.monthWeekFilterService.getFilteredWeek(week)
    }))
  }





}