import { Injectable, OnDestroy } from '@angular/core';
import { AttendanceAPIService } from '../../../eattendance/services/attendanceAPI.service';
import * as attendanceTypes from 'projects/fortunebit-staffhub/src/eattendance/services/typings/eattendance-api-typings'
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import * as moment from 'moment';
import { GlobalVariables } from 'fortunebit-staffhub/src/core/globalvar.service';
import { debounceTime, distinctUntilChanged, skip, take } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
// import { ChangeDetectionService } from '../../shared/change-detection.service';

export interface weeksStartEnd {
  startDate: string
  endDate: string
}
export var FilterTypeMonth = {
  selectedMonth: ""
}
export var FilterTypeWeek = {
  selectedWeek: 0
}
@Injectable({
  providedIn: 'root'
})

export class AttendanceMonthWeekFilterService {

  public momentInstance = moment()
  public monthList = moment.months()
  public currentMonthStr: string = this.momentInstance.toString()
  public currentDateWeekIndex: number
  public startDate: string
  public endDate: string
  public selectedMonthStr: string = '00'
  public selectedMonth: string = '00'
  public selectedYear: string = '00'
  public currentYear: string = this.momentInstance.year().toString()
  public weeksAccodMonth: Array<weeksStartEnd> = []
  public selectedWeek: weeksStartEnd
  public selectedWeekFilter: string
  public weeksDatas: Array<any> = []
  public subscriptionsHolder: Subscription[] = []
  public apiSubscriptionsHolder: Subscription[] = []
  public listnerSubscriptionsHolder: Subscription[] = []
  public initialWeeksAccodMonth: string = "Week 0: 03-02-2023 - 04-02-2023"

  public subsMonth: Subscription

  public $attendanceMonthChange = new BehaviorSubject(this.selectedMonthStr)
  public _attendanceMonthChanges = this.$attendanceMonthChange.asObservable()

  public $userAttendanceStatusSummary: BehaviorSubject<attendanceTypes.UserMonthlyWeekAttendanceSummaryOutput[]> = new BehaviorSubject([])
  public _userAttendanceStatusSummary = this.$userAttendanceStatusSummary.asObservable()

  public $userFlexiMonthlyWeekAttendanceList: BehaviorSubject<attendanceTypes.UserMonthlyWeelAttendanceList[]> = new BehaviorSubject([])
  public _userFlexiMonthlyWeekAttendanceList = this.$userFlexiMonthlyWeekAttendanceList.asObservable()

  // Change detection for subcribers
  public changeDetection: BehaviorSubject<attendanceTypes.UserAttendanceSummaryInput> = new BehaviorSubject(this.changeOnFilter())
  public $changeDetection = this.changeDetection.asObservable()

  // public changeDetectionOnFilter: BehaviorSubject<attendanceTypes.UserAttendanceSummaryInput> = new BehaviorSubject(this.changeOnFilter())
  // public $changeDetectionOnFilter = this.changeDetectionOnFilter.asObservable()


  public flexiListSkeleton$: BehaviorSubject<boolean> = new BehaviorSubject(false)
  public destroy$ = new Subject();
  public monthType : any = [{
    'January': 1,
    'February':2,
    'March':3,
    'April':4,
    'May': 5,
    'June': 6,
    'July':7,
    'August':8,
    'September':9,
    'October':10,
    'November': 11,
    'December':12
}]
  constructor(
    private globalService: GlobalVariables,
    // private changeDetact: ChangeDetectService,
    private attendanceAPIService: AttendanceAPIService
  ) {
    this.setSelectedMonth(moment(this.currentMonthStr).format('MM'))
    this.setSelectedYear(this.currentYear)
    this.getCurrentMonthWeeksDatas()
    this.listenChangeDetaction()
    this.monthChangeListnerOnFilter()
    this.refreshPageListner()
  }

  changeOnFilter(): attendanceTypes.UserAttendanceSummaryInput {
    return {
      employee: this.globalService.user_global.employee_id,
      month: this.getSelectedMonth(),
      year: this.getSelectedYear(),
      start_date: this.getStartDate(),
      end_date: this.getEndDate(),
    }
  }

  setSelectedWeek(week: string) {
    this.selectedWeekFilter = week
  }

  getSelectedWeek() {
    return this.selectedWeekFilter
  }

  setSelectedMonth(month: string) {
    this.selectedMonth = month
  }

  getSelectedMonth() {
    return this.selectedMonth
  }

  setSelectedYear(year: string) {
    this.selectedYear = year
  }

  getSelectedYear() {
    return this.selectedYear
  }

  async setStartDate(startDate: string) {
    this.startDate = startDate
  }

  async setEndDate(endDate: string) {
    this.endDate = endDate
  }

  getStartDate() {
    return this.startDate
  }

  getEndDate() {
    return this.endDate
  }

  refreshPageListner() {
    // this.listnerSubscriptionsHolder.push(this.changeDetact.$refreshPage.subscribe(refresh => {
    //   // console.log({ refresh });
    //   // below function trigger to refresh current month data
    //   //  in case arvinder want refresh selected/filterred month and week just add triggerChangeDetactionOnFilter() function
    //   this.getCurrentMonthWeeksDatas()
    //   this.getUserFlexiMonthlyWeekAttendanceList()
    //   this.setSelectedYear(this.currentYear)
    //   this.triggerChangeDetaction()
    // }))
  }

  listenChangeDetaction() {
    this.$changeDetection.pipe(debounceTime(200)).subscribe(filters => {
      // console.log({ filters });
      // this.getSelectedMonthWeekDatas()
      this.getUserAttendanceStatusSummary(filters)
      this.getUserMonthlyWeekAttendanceList(filters)
    })
  }

  monthChangeListnerOnFilter() {
    this.listnerSubscriptionsHolder.push(this._attendanceMonthChanges.pipe(skip(1)).subscribe(month => {
      // console.log({ month });
      this.weeksAccodMonth = []
      this.setSelectedMonth(month)
      this.getSelectedMonthWeekDatas()
    }))
  }

  triggerChangeDetaction() {
    this.changeDetection.next(this.changeOnFilter())
  }

  public unsubscribeApiSub() {
    if (this.apiSubscriptionsHolder.length > 0) {
      this.apiSubscriptionsHolder.forEach(sub => {
        sub.unsubscribe()
      })
    }
  }

  public unsubscribeListnerSub() {
    if (this.listnerSubscriptionsHolder.length > 0) {
      this.listnerSubscriptionsHolder.forEach(sub => {
        sub.unsubscribe()
      })
    }
  }

  public applyFilter() {
    this.triggerChangeDetaction()
  }

  public async getFilteredMonth(month: string) {
    // console.log(this.monthType[0][month]);
    if (this.monthType[0][month] < 10) {
      const selectedmonth = '0' + this.monthType[0][month]
      this.$attendanceMonthChange.next(selectedmonth)
    } else {
      const selectedmonth = this.monthType[0][month].toString()
      this.$attendanceMonthChange.next(selectedmonth)
    }
  }

  public async getFilteredWeek(weekIndex: number) {
    // console.log(this.weeksAccodMonth);
    // this.weeksAccodMonth.filter((x, i) => i === weekIndex)[0]
    this.selectedWeek = this.weeksAccodMonth[weekIndex]
    // console.log(this.selectedWeek)
    this.setStartDate(this.selectedWeek?.startDate)
    this.setEndDate(this.selectedWeek?.endDate)
    this.setSelectedWeek(`Week ${weekIndex + 1}: ${moment(this.getStartDate()).format('DD-MM-YYYY')} - ${moment(this.getEndDate()).format('DD-MM-YYYY')}`)
    this.triggerChangeDetaction()
  }

  private async getMonthlyWeeksTemplate(data: attendanceTypes.TotalMonthInput): Promise<any> {
    // console.log(data)
    return new Promise((resolve, reject) => {
      this.subscriptionsHolder.push(this.attendanceAPIService.getMonthlyWeeksTemplate({ ...data }).pipe(
        debounceTime(1000)
      ).subscribe({
        next: (weeks) => {
          let include = weeks['message']
          resolve(weeks['message'])
        },
        complete: () => {
          console.log('completed getMonthlyWeeksTemplate');
        },
        error: (error) => {
          console.log(error);
          reject(error)
        }
      }))
    }).catch(err => {
      // console.log(err);
    })
  }

  async getCurrentWeekIndex(data: Array<any>) {
    // console.log({data})
    for (let index = 0; index < data?.length; index++) {
      if (data[index][3].includes(moment().format('YYYY-MM-DD'))) {
        return index
      }
    }
  }

  public getSelectedMonthWeekDatas() {
    this.getMonthlyWeeksTemplate({
      month: this.getSelectedMonth(),
      year: this.getSelectedYear()
    }).then(async (weekDatas) => {
      // console.log({ weekDatas })
      this.weeksAccodMonth = []
      weekDatas?.forEach((x, i) => {
        this.weeksAccodMonth.push({
          startDate: moment.parseZone(x[0]).format('YYYY-MM-DD'),
          endDate: moment.parseZone(x[1]).format('YYYY-MM-DD')
        })
      })
    })
  }

  private async getUserAttendanceStatusSummary(input: attendanceTypes.UserAttendanceSummaryInput): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      this.subscriptionsHolder.push(this.attendanceAPIService.getUserMonthlyWeekAttendanceStatusSummary({ ...input }).pipe(
        debounceTime(500)
      ).subscribe({
        next: (output) => {
          resolve(output['message']['data'])
          this.$userAttendanceStatusSummary.next(output['message']['data'])
        },
        complete: () => {
          console.log('completed getUserMonthlyWeekAttendanceStatusSummary');
        },
        error: (error) => {
          console.log(error);
          reject(error)
        }
      })
      )
    })
  }

  public async getUserMonthlyWeeksSummary() {
    return new Promise((resolve, reject) => {
      // console.log(this.selectedMonthStr, this.getStartDate(), this.getEndDate())
      this.getUserAttendanceStatusSummary({
        employee: this.globalService.user_global.employee_id,
        month: this.selectedMonthStr,
        year: this.currentYear.toString(),
        start_date: this.getStartDate(),
        end_date: this.getEndDate()
      }).then((summary) => {
        console.log({ summary });
        if (summary.length > 0) {
          resolve(summary[0])
          this.flexiListSkeleton$.next(false)
        } else {
          resolve([])
          this.flexiListSkeleton$.next(false)
        }
      }).catch((error) => {
        // console.log(error);
        reject(error)
      })
    })
  }

  private async getUserMonthlyWeekAttendanceList(input: attendanceTypes.UserAttendanceSummaryInput): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      this.subscriptionsHolder.push(this.attendanceAPIService.getUserFlexiMonthlyWeekAttendanceList({ ...input })
        .subscribe({
          next: (output) => {
            resolve(output['message']['data'])
            this.$userFlexiMonthlyWeekAttendanceList.next(output['message']['data'])
          },
          complete: () => {
            console.log('completed getUserFlexiMonthlyWeekAttendanceList');
          },
          error: (error) => {
            console.log(error);
            reject(error)
          }
        })
      )
    })
  }

  public async getUserFlexiMonthlyWeekAttendanceList() {
    return new Promise((resolve, reject) => {
      this.getUserMonthlyWeekAttendanceList({
        employee: this.globalService.user_global.employee_id,
        start_date: this.getStartDate(),
        end_date: this.getEndDate()
      }).then((childs: Array<attendanceTypes.UserMonthlyWeelAttendanceList>) => {
        // console.log({ childs });
        resolve(childs)
        this.flexiListSkeleton$.next(false)
      }).catch(error => {
        reject(error)
      })
    })
  }

  public async setMonthString(month: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let selectedMonthInt = this.monthList.indexOf(month) + 1
      if (selectedMonthInt < 10) {
        this.selectedMonthStr = '0' + selectedMonthInt.toString()
      } else {
        this.selectedMonthStr = selectedMonthInt.toString()
      }
      resolve(true)
    })
  }

  public async getCurrentMonthWeeksDatas() {
    this.getMonthlyWeeksTemplate({
      month: this.getSelectedMonth(),
      year: this.getSelectedYear()
    }).then(async (weekDatas) => {
      // console.log({ weekDatas })
      this.weeksAccodMonth = []
      this.currentDateWeekIndex = await this.getCurrentWeekIndex(weekDatas)
      weekDatas?.forEach((x, i) => {
        this.weeksAccodMonth.push({
          startDate: moment.parseZone(x[0]).format('YYYY-MM-DD'),
          endDate: moment.parseZone(x[1]).format('YYYY-MM-DD')
        })
      })
      this.selectedWeek = this.weeksAccodMonth.filter((x, i) => i === this.currentDateWeekIndex)[0]
      // console.log(this.selectedWeek)
      this.setStartDate(this.selectedWeek?.startDate)
      this.setEndDate(this.selectedWeek?.endDate)
      this.setSelectedWeek(`Week ${this.currentDateWeekIndex + 1}: ${moment(this.getStartDate()).format('DD-MM-YYYY')} - ${moment(this.getEndDate()).format('DD-MM-YYYY')}`)
      setTimeout(() => {
        this.triggerChangeDetaction()
      }, 100);
    })

  }

  setMarginTop(height: number) {
    if (height <= 656) {
      return '30'
    } else if (height > 656 && height <= 660) {
      return '30'
    } else if (height > 660 && height <= 667) {
      return '40'
    } else if (height > 667 && height <= 740) {
      return '30'
    } else if (height > 740 && height <= 778) {
      return '30'
    } else if (height > 778 && height <= 799) {
      return '30'
    } else if (height > 799 && height <= 844) {
      return '38'
    } else if (height > 844 && height <= 851) {
      return '30'
    } else if (height > 851 && height <= 896) {
      return '38'
    } else if (height > 896 && height <= 915) {
      return '34'
    } else {
      return '15'
    }
  }

  setHeight(height: number) {
    if (height <= 567) {
      return 18
    } else if (height > 567 && height <= 640) {
      return 16
    } else if (height > 640 && height <= 656) {
      return 16
    } else if (height > 656 && height <= 660) {
      return 16
    } else if (height > 660 && height <= 667) {
      return 17
    } else if (height > 667 && height <= 740) {
      return 14
    } else if (height > 740 && height <= 778) {
      return 13
    } else if (height > 778 && height <= 799) {
      return 13
    } else if (height > 799 && height <= 844) {
      return 13
    } else if (height > 844 && height <= 851) {
      return 12
    } else if (height > 851 && height <= 896) {
      return 12
    } else if (height > 851 && height <= 896) {
      return 12
    } else if (height > 896 && height <= 915) {
      return 12
    } else {
      return 15
    }
  }

  public unsubscribeSub() {
    if (this.subscriptionsHolder.length > 0) {
      this.subscriptionsHolder.forEach(sub => {
        sub.unsubscribe()
      })
    }
  }

}


