import { Component, Inject, OnInit } from '@angular/core';
import { SidemenuService } from '@desktop-common-comp/services/sidemenu.service';
import { GlobalVariables, SideMenuEmitService } from 'fortunebit-staffhub/src/libs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  public titleHeader = "E-Profile"
  public descHeader = "Staff Hub"

  constructor(
    @Inject(GlobalVariables) public global: GlobalVariables,
    @Inject('SIDEMENUGLOBAL') private sideMenuService: SideMenuEmitService
  ) {
    this.sideMenuService.selectedItem.next('profile')
  }

  ngOnInit(): void { }

  getUserAge(bday) {
    let current_date = new Date()
    let current_year = current_date.getFullYear()
    let birth_date = new Date(bday)
    let birth_year = birth_date.getFullYear()
    return current_year - birth_year
  }

}
