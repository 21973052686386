<div class="select-month-year"
  [ngClass]="{'select-month-year': enableMonth && enableYear, 'only-one-selection': enableMonth || enableYear }">
  <div *ngIf="enableMonth" style="width: 50%; padding: 0px 12px">
    <div class="selectedMonth"><b>Select Month</b></div>
    <div class="relative">
      <ng-select class="month-select month" #monthSelect [items]="monthsList" bindLabel="month_of_eleave"
        [multiple]="false" [closeOnSelect]="true" [clearable]="true" [loading]="monthLoading" [typeahead]="monthInput$"
        typeToSearchText="Month Selection" (remove)="onRemoveMonth($event)" [virtualScroll]="true" appearance="outline"
        [(ngModel)]="selectedMonth" [searchable]="false">
      </ng-select>
      <i class="fa fa-search"></i>
    </div>
  </div>
  <div *ngIf="enableYear" style="width: 50%; padding: 0px 12px">
    <div class="selectedYear"><b>Select Year</b></div>
    <div class="relative">
      <ng-select class="year-select" #yearSelect [items]="yearList" bindLabel="year_of_eleave" [multiple]="false"
        [closeOnSelect]="true" [clearable]="true" [loading]="yearLoading" [typeahead]="yearInput$"
        typeToSearchText="Year Selection" (remove)="onRemoveYear($event)" [virtualScroll]="true" appearance="outline"
        [(ngModel)]="selectedYear" [searchable]="false">
      </ng-select>
      <i class="fa fa-search"></i>
    </div>
  </div>
</div>