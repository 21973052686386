import { EventEmitter, Injectable, } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Subscribable, Subscription, Observable } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import SwiperCore from 'swiper'
import { cs_log } from '@handler/logger.handler'
import { NgOption } from "@ng-select/ng-select"

export interface MonthYear {
  selectedMonthString: string
  selectedMonthNum: number
  selectedYear: number
}
export interface MonthsYear {
  month_of_leave: string,
  metadata?: NgOption
}
@Injectable({
  providedIn: 'root'
})
export class MonthYearListFilterService {
  public baseStruct: MonthYear
  public momentInstance = moment()
  public monthList = moment.months()
  public currentMonthNum: number = 0
  public currentMonthStr: string
  public currentYear: number = this.momentInstance.year()
  public $monthFilterChangeDetection: BehaviorSubject<MonthYear>
  public _monthFilterSubscription: Observable<MonthYear>

  constructor() {
    // Start Month Year Listener
    // this._listen_month_filter()
    this.refreshMonthYear()
    this.buildBaseStruct()
    this.$monthFilterChangeDetection = new BehaviorSubject(<MonthYear>this.baseStruct)
    this._monthFilterSubscription = this.$monthFilterChangeDetection.asObservable()
    cs_log(this)
  }

  buildBaseStruct() {
    return this.baseStruct = {
      selectedMonthNum: this.currentMonthNum + 1,
      selectedMonthString: this.currentMonthStr,
      selectedYear: this.currentYear
    }
  }

  refreshMonthYear() {
    this.currentMonthNum = this.momentInstance.month()
    this.getCurrentMonth()
    this.currentYear = this.momentInstance.year()
  }

  getCurrentMonth() {
    this.currentMonthStr = this.monthList[this.currentMonthNum]
    // console.log(this.currentMonthStr, 'currentMonthStr');
  }

  // selectedMonth(monthIndex: number) {
  //   this.currentMonthNum = monthIndex
  //   this.momentInstance = moment().month(this.currentMonthNum).year(this.currentYear)
  // }

  async applyFilter() {
    this.getCurrentMonth()
    this.buildBaseStruct() // Send request to service
    this.emitChanges()
  }

  emitChanges() {
    // console.log(this.baseStruct)
    this.$monthFilterChangeDetection.next(this.baseStruct)
  }

  async setMargin(height: number) {
    if (height <= 567) {
      return Math.round(height - (height * (96.45 / 100)))
    } else if (height > 567 && height <= 660) {
      return Math.round(height - (height * (97.70 / 100)))
    } else if (height > 660 && height <= 667) {
      return Math.round(height - (height * (95.50 / 100)))
    } else if (height > 667 && height <= 740) {
      return Math.round(height - (height * (97.25 / 100)))
    } else if (height > 740 && height <= 778) {
      return Math.round(height - (height * (96.90 / 100)))
    } else if (height > 778 && height <= 799) {
      return Math.round(height - (height * (96.85 / 100)))
    } else if (height > 799 && height <= 844) {
      return Math.round(height - (height * (96.40 / 100)))
    } else if (height > 844 && height <= 851) {
      return Math.round(height - (height * (97.60 / 100)))
    } else if (height > 851 && height <= 896) {
      return Math.round(height - (height * (96.6 / 100)))
    } else if (height > 896 && height <= 915) {
      return Math.round(height - (height * (97.25 / 100)))
    } else {
      return Math.round(height - (height * (97.8 / 100)))
    }
  }


}
