<div class="d-flex balance-title text-center select-month-year">

  <div style="margin-right: 5px;width:50%">
    <div class="selectedMonth"><b>Select Month</b></div>
    <div class="relative">
      <ng-select class="monthSelect" #monthSelects [items]="monthList" bindLabel="month_of_eleave" [multiple]="false"
        [closeOnSelect]="true" [clearable]="true" [loading]="monthyearLoading" [typeahead]="monthyearInput$"
        typeToSearchText="Month Year Selection" (remove)="onRemove($event)" [virtualScroll]="true" appearance="outline"
        [(ngModel)]="selectedMonth" [searchable]="false">
      </ng-select>
      <!-- <i class="fa fa-search"></i> -->
    </div>
  </div>

  <div style="margin-right: 5px;width:50%">
    <div class="selectedYears"><b>Select Year</b></div>
    <div class="relative">
      <ng-select #yearSelects [items]="yearList" bindLabel="year_of_eleave" [multiple]="false" [closeOnSelect]="true"
        [clearable]="true" [loading]="monthyearLoading" [typeahead]="monthyearInput$"
        typeToSearchText="Month Year Selection" (remove)="onRemove($event)" [virtualScroll]="true" appearance="outline"
        [(ngModel)]="selectedYear" [searchable]="false">
      </ng-select>
      <!-- <i class="fa fa-search"></i> -->
    </div>
  </div>

</div>