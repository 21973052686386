import { Component, OnInit, ViewChild, AfterViewInit, Input, Output, OnDestroy, EventEmitter } from '@angular/core';
import { NgSelectComponent, NgOption } from '@ng-select/ng-select'
import { Observable, Subject, concat, of, pipe } from 'rxjs'
import { catchError, debounceTime, distinctUntilChanged, pluck, skip, switchMap, tap } from 'rxjs/operators'
import { CommonServiceAPI } from 'fortunebit-staffhub/src/core/common-api.service'

export interface Employees {
  value: string,
  description: string,
  metadata?: NgOption
}

@Component({
  selector: 'app-ngselect-employee-select',
  templateUrl: './ngselect-employee-select.component.html',
  styleUrls: ['./ngselect-employee-select.component.scss'],
})
export class NgselectEmployeeSelectComponent implements OnInit, AfterViewInit, OnDestroy {

  public disableEmployee: boolean = false

  employee$: Observable<Employees[]>
  employeeLoading = false
  employeeInput$ = new Subject<string>()
  @Input('selectedEmployee') public selectedEmployee: Employees[] = []
  @Input('replaceSelected') public replaceSelected = new EventEmitter<Array<Employees>>()
  @Input('removeSelected') public removeSelected = new EventEmitter<string>()
  @Output('selectedEmployeeOutput') public selectedEmployeeOutput = new EventEmitter<Array<Employees>>()
  @ViewChild("employeeSelect") public employeeSelect: NgSelectComponent
  constructor(
    private commonAPI: CommonServiceAPI
  ) {
    console.log('started Employee Filter')
  }

  ngOnInit() {
    this.selectedEmployee = this.getDefaultUserList()
  }

  ngAfterViewInit(): void {
    // Init Default value - From approver service
    this.loadEmployee()
    this.trackChanges()
    this.removeSelectedUser()
    this.replaceSelectedUser()
  }

  getDefaultUserList() {
    return this.selectedEmployee
  }

  setDisableSelect(disable: boolean) {
    this.disableEmployee = disable
  }

  replaceSelectedUser() {
    this.replaceSelected.subscribe((replaceSelected) => {
      // Clear all selected employee
      this.employeeSelect.itemsList.clearSelected()

      replaceSelected.forEach((sel) => {
        sel.metadata.selected = false
        this.employeeSelect.itemsList.select(sel.metadata)
      })

      this.employeeSelect.detectChanges()
      // Attach new selected items

    })
  }

  removeSelectedUser() {
    this.removeSelected.subscribe((dataSelected: string) => {
      // check if selected user exist
      this.employeeSelect.itemsList.selectedItems.forEach(data => {
        if (data.value.value == dataSelected) {
          this.employeeSelect.itemsList.unselect(data)
          this.employeeSelect.detectChanges()
          // Send latest employee detail after removal
          this.selectedEmployeeOutput.emit(this.employeeSelect.selectedValues)
        }
      })
    })
  }

  clearAll() {
    this.employeeSelect.clearModel()
  }

  ngOnDestroy(): void {
    this.removeSelected.unsubscribe()
    this.selectedEmployeeOutput.unsubscribe()
  }

  private loadEmployee() {
    this.employee$ = concat(
      of([]),
      this.commonAPI.getEmployeeList({ 'txt': '' }).pipe(
        tap(() => this.employeeLoading = true),
        distinctUntilChanged(),
        tap(() => this.employeeLoading = false),
        pluck('results')
      ),
      this.employeeInput$.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => this.employeeLoading = true),
        switchMap(val => this.commonAPI.getEmployeeList({ 'txt': val }).pipe(
          catchError(() => of([])),
          tap(() => this.employeeLoading = false),
          pluck('results')
        ))
      )
    )
  }

  trackByFn(item: Employees) {
    return item.value;
  }

  closeFilter() { }

  public getSelectedUser() {
    return this.selectedEmployee
  }

  dataInterceptor(data: Array<Employees>) {
    console.log({ data });

    data.forEach(emp => {
      emp.metadata = this.employeeSelect.itemsList.selectedItems.find((val) => {
        if (val.value['value'] == emp.value) {
          return true
        }

        return false
      })
    })
    return data
  }

  trackChanges() {
    this.employeeSelect.changeEvent
      .subscribe(data => {

        this.selectedEmployeeOutput.emit(this.dataInterceptor(data))
      })
  }

  onSelectionChange(event) {
    // this.companySelect.close()
  }

  onItemAdded(event) {
    // this.companySelect.close();
  }

  onDropdownClose(employeeSelect: any) {
    employeeSelect.clearSearch();
  }

}
