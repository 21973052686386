import * as eleaveTypes from 'projects/fortunebit-staffhub/src/eleave/services/typings/eleave-api.typings'
import { Inject, Injectable } from '@angular/core';
import { ExpenseEleaveApi } from 'fortunebit-staffhub/src/libs';
import { GlobalVariables } from 'fortunebit-staffhub/src/core/globalvar.service';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ToFromDateService } from '@desktop-common-comp/services/to-from-date.service';
import { LoaderService } from '@desktop-common-comp/services/loader.service';
import { cs_log } from '@handler/logger.handler';
import { debounceTime } from 'rxjs/operators';
import { NgselectMonthLeaveSelectComponent } from '@desktop-common-comp/components/ngselect-month-leave-select/ngselect-month-leave-select.component';
import { BehaviorSubject } from 'rxjs';
import { ChangeDetectionService } from '../../shared/change-detection.service';


interface MonthYear {
    month: Moment
    year: Moment
}

@Injectable()
export class EleaveListingService {

    public monthSelectInstance: NgselectMonthLeaveSelectComponent
    public selectedMonthDict: Array<eleaveTypes.Months> = []
    public leaveAllocationDetails: Array<eleaveTypes.LeaveAllocArrayDetails> = []
    public selectedMonth: string = ''
    public employeeId: string = ''
    public selectedFromDate: string = ''
    public orderBy: 'from_date' | 'creation' = 'from_date'
    public selectedToDate: string = ''
    public selectedLeaveType: string = ''
    public selectedSatus: 'All' | 'Pending' | 'Approved' | 'Rejected' | 'Cancelled'
    public all_months: Array<string>


    public changeDetection: BehaviorSubject<eleaveTypes.EleaveLisingFilterMeta> = new BehaviorSubject(this.buildFilterObject())
    public $changeDetection = this.changeDetection.asObservable()

    constructor(
        @Inject(LoaderService) private loader: LoaderService,
        @Inject(GlobalVariables) private global: GlobalVariables,
        @Inject(ExpenseEleaveApi) private eleaveAPI: ExpenseEleaveApi,
        @Inject(ToFromDateService) private toFromDateService: ToFromDateService,
        @Inject(ChangeDetectionService) private changeDetact: ChangeDetectionService
    ) {
        this.getAllMonths()
        this.initLeaveParams()
    }

    initSubscriptionOnFromToDate() {
        this.toFromDateService.ToFromDateListner.subscribe(dates => {
            console.log({ dates });

        })
    }

    initLeaveParams() {
        const fromDate = moment().startOf('year').format('YYYY-MM-DD');
        const toDate = moment().endOf('year').format('YYYY-MM-DD');
        this.setFromDate(fromDate)
        this.setToDate(toDate)
        this.setLeaveType('All')
        this.setStatus('All')
        // this.leaveListing()
    }

    setOrderBy(order: 'from_date' | 'creation') {
        this.orderBy = order
    }

    getOrderBy() {
        return this.orderBy
    }

    setFromDate(from_date) {
        this.selectedFromDate = from_date
    }

    getFromDate() {
        return this.selectedFromDate
    }

    setToDate(to_date) {
        this.selectedToDate = to_date
    }

    getToDate() {
        return this.selectedToDate
    }

    setLeaveType(leave_type) {
        this.selectedLeaveType = leave_type
    }

    getLeaveType() {
        return this.selectedLeaveType
    }

    setStatus(status: 'All' | 'Pending' | 'Approved' | 'Rejected' | 'Cancelled') {
        this.selectedSatus = status
    }

    getStatus() {
        return this.selectedSatus
    }

    buildFilterObject(): eleaveTypes.EleaveLisingFilterMeta {
        return {
            employee: this.global.user_global.employee_id,
            from_date: this.getFromDate(),
            to_date: this.getToDate(),
            leave_type: this.getLeaveType(),
            status: this.getStatus(),
            order_by: this.getOrderBy()
            // filter_month: this.selectedMonth,
            // company: '*',
            // employee: '',
            // status: 'Pending'
        }
    }

    getAllMonths() {
        this.all_months = moment.months()
        return this.all_months
    }

    getBalanceLeaveList() {
        return new Promise((resolve, reject) => {
            this.eleaveAPI.getLeaveBalanceList({
                employee: this.global.user_global.employee_id
            }).subscribe(data => {
                resolve(data['message'])
            })
        })
    }

    newBalanceList() {
        return new Promise((resolve, reject) => {
            this.eleaveAPI.getNewLeaveBalanceList({
                'employee': this.global.user_global.employee_id,
                'date': moment(new Date()).format('YYYY-MM-DD')
            }).pipe(
                debounceTime(100)
            ).subscribe(data => {
                resolve(data['message'])
            })
        })
    }

    initLeaveBalance() {
        return new Promise((resolve, reject) => {
            this.newBalanceList().then((data: eleaveTypes.LeaveBalance) => {
                // console.log({ data });
                this.leaveAllocationDetails = []
                this.destructLeaveData(data)
                resolve(data)
            });
        }).catch((err) => {
            console.log(err);
        })
    }



    leaveListing() {
        return new Promise((resolve, reject) => {
            this.eleaveAPI.getLeaveApplicationList(this.buildFilterObject()).subscribe(data => {
                // console.log({ data });
                if (data['message']) {
                    resolve(data['message'])
                    //   this.leaveListSkeleton$.next(false)
                }
            }, err => {
                reject(err)
            })
        })
    }

    // leaveList(from_date, to_date) {
    //     return new Promise(async (resolve, reject) => {
    //         await this.loader.presentGlobalLoading()
    //         this.eleaveAPI.getLeaveApplicationList({
    //             filters: `["Leave Application","creation","Between",["${from_date}","${to_date}"],["Leave Application","employee","=","${this.global.user_global.employee_id}"]]`,
    //             status: 0
    //         }).subscribe(data => {
    //             if (data) {
    //                 this.loader.hideGlobalLoading()
    //                 resolve(data['data'])
    //             }
    //         }, error => {
    //             this.loader.hideGlobalLoading()
    //             reject(error)
    //         })
    //     })
    // }

    getDatesObject() {
        return new Promise((resolve, reject) => {
            resolve({
                from_date: this.toFromDateService.from_date.format('YYYY-MM-DD'),
                to_date: this.toFromDateService.to_date.format('YYYY-MM-DD')
            })
        })
    }

    // Month Manipulation for selection

    setMonthSelectInstance(component: NgselectMonthLeaveSelectComponent) {
        this.monthSelectInstance = component
        this.setupMonthSelect()
        cs_log(this, 'Month select Mapped to Service')
    }

    setupMonthSelect() {
        this.monthSelectInstance.selectedMonthOutput
            .pipe(
                debounceTime(500)
            ).subscribe((month: Array<eleaveTypes.Months>) => {
                this.selectedMonthDict = month
                this.applyFilter()
                // console.log({ month });
            })
    }

    async applyFilter() {
        await this.setFilterMonth(this.selectedMonthDict)
        await this.triggerChangeDetection()
    }

    async triggerChangeDetection() {
        this.changeDetection.next(this.buildFilterObject())
        this.changeDetact.triggerFilterChange(true)
    }

    setFilterMonth(month) {
        // console.log({ month });
        return new Promise((resolve, reject) => {
            if (month.length > 0) {
                if (month[0]['selectedMonth'] != "") {
                    const indexMonth = this.all_months.findIndex(element => {
                        if (element === month[0]['selectedMonth']) {
                            return true
                        }
                        return false
                    })
                    this.selectedMonth = (indexMonth + 1).toString()
                } else {
                    this.selectedMonth = ""
                }
            } else {
                this.selectedMonth = ""
            }
            this.setClearFilter()
            resolve(true)
        })
    }

    setClearFilter() {
        if (this.selectedMonth != '') {
            // this.changeDetact.triggerFilterMonthChange(true)
        } else {
            // this.changeDetact.triggerFilterMonthChange(false)
        }
    }

    destructLeaveData(allocation: eleaveTypes.LeaveBalance) {
        for (const [key, value] of Object.entries(allocation.leave_allocation)) {
            this.leaveAllocationDetails.push(this.segregateLeaveDetails(key, value))
        }
    }

    segregateLeaveDetails(leave_type, allocation_details: eleaveTypes.LeaveDetails) {
        let normal_allocation = { from_date: '', to_date: '' }
        if (leave_type == 'Annual Leave') {
            // Get carry forward leaves
            let carry_forward_allocation = { from_date: '', to_date: '' }
            allocation_details.allocation_details.forEach(data => {
                if (data.is_carry_forward == 1) {
                    carry_forward_allocation.from_date = data.from_date
                    carry_forward_allocation.to_date = data.to_date
                } else {
                    normal_allocation.from_date = data.from_date
                    normal_allocation.to_date = data.to_date
                }
            })
            allocation_details.normal_allocation = normal_allocation
            allocation_details.carry_forward_allocation = carry_forward_allocation
        } else {
            if (allocation_details.allocation_details) {
                allocation_details.allocation_details.forEach(data => {
                    normal_allocation.from_date = data.from_date
                    normal_allocation.to_date = data.to_date
                })
            }
            allocation_details.normal_allocation = normal_allocation
        }

        return { leave_type, allocation_details }
    }


}
