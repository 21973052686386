import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthRememberService } from 'fortunebit-staffhub/src/auth/auth_remember.service';
import { AuthGuardService } from 'fortunebit-staffhub/src/auth/guards/auth_guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
    canActivate: [AuthRememberService],
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'eclaim',
    loadChildren: () =>
      import('./eclaim/eclaim.module').then((m) => m.EclaimModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'eleave',
    loadChildren: () =>
      import('./eleave/eleave.module').then((m) => m.EleaveModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'eattendance',
    loadChildren: () =>
      import('./eattendance/eattendance.module').then((m) => m.EattendanceModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./notification/notification.module').then(m => m.NotificationModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
