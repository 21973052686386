import { Inject, Injectable } from "@angular/core"
import { ExpenseEleaveApi } from "./eleave-api"
import { EleaveParentBaseArgs, EleaveSummaryInfo } from "./typings/eleave-api.typings"
import * as eleaveTypes from "./typings/eleave-api.typings";
import { debounceTime, delay, finalize } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})

export class EleaveApprovalCore {
  public baseArgs: EleaveParentBaseArgs
  // public role: ApprovalUserRole
  constructor(
    @Inject(ExpenseEleaveApi) private EleaveAPI: ExpenseEleaveApi
  ) { }

  getLeaveApproval(args: any): Promise<Array<EleaveSummaryInfo>> {
    return new Promise((resolve, reject) => {
      this.EleaveAPI.getApprovalLeaveListByUser(args).subscribe({
        next: (data) => {
          if (data['code'] == 200) {
            resolve(data['message'])
          } else {
            resolve([])
          }
        },
        complete: () => { console.log('completed getLeaveApproval') },
        error: (error) => { reject(error) }
      })
    })
  }

  getHrEmployeeList(args: any): Promise<Array<eleaveTypes.EleaveEmployeeDetailsHRDefault>> {
    return new Promise((resolve, reject) => {
      this.EleaveAPI.getHrEmployeeList(args).subscribe({
        next: (data) => {
          if (data['code'] == 200) {
            resolve(data['message'])
          } else {
            resolve([])
          }
        },
        complete: () => { console.log('completed getHrEmployeeList') },
        error: (error) => { reject(error) }
      })
    })
  }

  getHrApproveLeaveList(args: eleaveTypes.EleaveChildApproveBaseArgsHR): Promise<Array<eleaveTypes.LeaveApplicationDatas>> {
    return new Promise((resolve, reject) => {
      this.EleaveAPI.getHrApproveLeaveListNew(args).pipe(
        debounceTime(500)
      ).subscribe({
        next: (data) => {
          if (data['code'] == 200) {
            resolve(data['message'])
          } else {
            resolve([])
          }
        },
        complete: () => { console.log('completed getHrApproveLeaveList') },
        error: (error) => { reject(error) }
      })
    })
  }

  getChildLeaveApprovalList(args: eleaveTypes.EleaveParentBaseArgs): Promise<Array<eleaveTypes.LeaveApplicationDatas>> {
    return new Promise((resolve, reject) => {
      this.EleaveAPI.getApprovalLeaveList(args).subscribe({
        next: (data) => {
          if (data['code'] == 200) {
            resolve(data['message'])
          } else {
            resolve([])
          }
        },
        complete: () => { console.log('completed getChildLeaveApprovalList') },
        error: (error) => { reject(error) }
      })
    })
  }

  getHrEmployeeLeaveDetails(args: eleaveTypes.EmployeeLeaveDetailsArgsHR): Promise<eleaveTypes.LeaveAllocationData | any> {
    return new Promise((resolve, reject) => {
      this.EleaveAPI.getHrEmployeeLeaveDetails(args).subscribe({
        next: (data) => {
          if (data['code'] == 200) {
            resolve(data['message'])
          } else {
            resolve([])
          }
        },
        complete: () => { console.log('completed getHrEmployeeLeaveDetails') },
        error: (error) => { reject(error) }
      })
    })
  }

  submitHrRemark(data: any) {
    return new Promise((resolve, reject) => {
      this.EleaveAPI.updateHrLeaveApplicationRemark(data).subscribe({
        next: (data) => {
          if (data['code'] == 200) {
            resolve(data['message'])
          } else {
            resolve([])
          }
        },
        complete: () => { console.log('completed submitHrRemark') },
        error: (error) => { reject(error) }
      })
    })
  }

  // Approval Individual Leave
  approveRejectIndividualLeaves(args: eleaveTypes.ApproveLeaveInput): Promise<any> {
    return new Promise((resolve, reject) => {
      this.EleaveAPI.approveRejectIndividualLeaves(args).subscribe({
        next: (data) => {
          if (data['code'] == 200) {
            resolve(data['message'])
          } else {
            resolve([])
          }
        },
        complete: () => { console.log('completed approveRejectIndividualLeaves') },
        error: (error) => { reject(error) }
      })
    })
  }

  getLeaveDetailsSingleUser(args: eleaveTypes.EleaveParentBaseArgs): Promise<any> {
    return new Promise((resolve, reject) => {
      this.EleaveAPI.getApprovalLeaveListByUser(args).subscribe({
        next: (data) => {
          if (data['code'] == 200) {
            resolve(data['message'])
          } else {
            resolve([])
          }
        },
        complete: () => { console.log('completed getLeaveDetailsSingleUser') },
        error: (error) => { reject(error) }
      })
    })
  }

  getLeaveApproverSummaryList(args: eleaveTypes.GetCommonInput): Promise<Array<eleaveTypes.UserAndAprooverLeaveSummaryDatas>> {
    return new Promise((resolve, reject) => {
      this.EleaveAPI.getLeaveApproverSummaryList(args).pipe(
        debounceTime(500)
      ).subscribe({
        next: (data) => {
          if (data['code'] == 200) {
            resolve(data['message'])
          } else {
            resolve([])
          }
        },
        complete: () => { console.log('completed getLeaveApproverSummaryList') },
        error: (error) => {
          reject(error)
        }
      })
    })
  }

  approveRejectLeaveApplicationByCheck(args: eleaveTypes.GetCheckedInput) {
    return new Promise((resolve, reject) => {
      this.EleaveAPI.approveRejectLeaveApplicationByCheck(args).pipe(
        debounceTime(500)
      ).subscribe({
        next: (data) => {
          if (data['code'] == 200) {
            resolve(data['message'])
          } else {
            resolve([])
          }
        },
        complete: () => { console.log('completed approveRejectLeaveApplicationByCheck') },
        error: (error) => { reject(error) }
      })
    })
  }





}
